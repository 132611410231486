.Plans .welcome {
  padding-bottom: 12em;
}

.Plans .welcome h1 {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  font-size: 7.5vw;
  line-height: 7.85vw;
  padding-right: 1vw;
}

.Plans .welcome .cta {
  margin-top: 3em;
  margin-left: 0em; 
}

.Plans .welcome .slogan {
  padding-top: 21em;
}

.Plans .service-plans {
  margin-top: 16em;
  padding-top: 5em;
}

.Plans .service-plans h2,
.Plans .service-plans p {
  margin-left: 1.25vw;
}

.Plans .three-tier {
  padding-bottom: 37vw;
}

.Plans .service-plans h2 {
  line-height: 1.1;
  font-size: 4vw;
}

.Plans .service-plans h2 span:first-of-type {
  display: block;
}

.Plans .apruvd-decline {
  margin-top: 3em;
  margin-left: 2em;
}

.Plans .apruvd-custom {
  margin-top: 7em;
  margin-left: 14em;;
}

.Plans .apruvd-complete {
  margin-top: 6em;
  margin-left: 30em;
}

.Plans .apruvd-decline p {
  margin-right: 33%;
}

.Plans .apruvd-custom p {
  margin-right: 40%;
}

.Plans .three-tier .right h3 {
  font-size: 2.5vw;
  line-height: 1.2;
}

.Plans .three-tier .right a {
  color: #00FF99;
  font-size: 1.2vw;
}

.Plans .three-tier .right a img.right-arrow {
  max-height: 20px;
}

.Plans .three-tier .right a:hover {
  text-decoration: none;
  border-bottom: 1px solid #00FF99;
}

.Plans .welcome .service-plans p {
  line-height: 1.8;
  margin-right: 5em;
  margin-top: 2em;
  font-size: 1.1vw;
}

.Plans .welcome p {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: 1.6vw;
}

.Plans .welcome .slogan p {
  margin-right: 2.75vw;
}

.Plans .more {
  padding-top: 6.5em;
  padding-bottom: 0;
}

.Plans .get-started {
  padding-top: 2.35em;
  padding-bottom: 5.35em;
}

.Plans .get-started h3 {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  font-size: 3.85vw;
  line-height: 1.25;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.Plans #plans-list {
  position: relative;
  top: -6em;
}

.Plans .detect-decline {
  padding-top: 21em;
  padding-bottom: 3em;
}

.Plans .detect-decline .col-7 {
  padding-left: 1.5vw;
}

.Plans .detect-decline .col-7 div {
  border-left: 1px dashed #00FF99;
  border-bottom: 1px dashed #00FF99;
  margin-right: 10vw;
  padding-left: 3vw;
}

.Plans .detect-decline .col-7 div h2:first-of-type {
  position: relative;
  top: -0.05em;
  line-height: 0.5;
}

.Plans .detect-decline .col-7 div h2:last-of-type {
  background-color: #14123d;
  position: relative;
  top: .4em;
  left: 5vw;
  margin-right: 1vw;
  padding-left: 2vw;
}

.Plans .detect-decline .col-7 p {
  margin-top: 2em;
  margin-left: 3vw;
  font-size: 1vw;
  margin-right: 6vw;
}

/* .Plans .detect-decline .center.col-md-4:f {
  padding-top: 20em;
  padding-bottom: 20em;
  padding-right: 4vw;
} */

.Plans .detect-decline .col-md-6:first-child h3:first-child {
  margin-top: .5em;
  margin-bottom: 1.5em;
  padding-left: 2vw;
  font-size: 2.25vw;
}

.Plans .detect-decline .col-5 h3:first-child {
  margin-left: 1vw;
  margin-top: 1.5em;
  margin-bottom: .25em;
  font-size: 2.25vw;
}

.Plans .detect-decline .col-5 hr {
  margin-top: 0;
  width: 15%;
  border-top: 1px dashed #00FF99;
}

.Plans .detect-decline h2 {
  font-size: 3vw;
}

.Plans .detect-decline .col-5 p {
  font-size: .9vw;
  padding: 0 7.5vw;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
}

.Plans .detect-decline .col-md-6:last-child a {
  margin-top: 25px;
  color: #fff;
}

.Plans .detect-decline .col-md-6:first-child h3 {
  padding-left: 4.5vw;
  margin-bottom: 1em;
}

/* .Plans .detect-decline a {
  border-bottom: 1px solid transparent;
}

.Plans .detect-decline a:hover {
  text-decoration: none;
  border-bottom: 1px solid #00FF99;
}

.Plans .detect-decline a::after {
  content: "\203A";
  color: #00FF99;
  font-size: 1.15vw;
} */

.Plans .how-we-work {
  margin-top: 18em;
}

.Plans .how-we-work h4 {
  margin-bottom: 1em;
}

.Plans .how-we-work h3 {
  font-size: 3vw;
  margin-bottom: 0;
}

.Plans .how-we-work h2 {
  font-size: 3.85vw;
  margin-bottom: .35em;
}

.Plans .how-we-work p {
  font-size: .95vw;
  font-weight: bold;
  letter-spacing: .1em;
  line-height: 2.25;
  margin-top: 1em;
  margin-bottom: 4em;
}

.Plans .how-we-work .row {
  margin-top: 6em;
}

.Plans .how-we-work .row .col-md-4 {
  padding-top: 16em;
}

.Plans .how-we-work .row h4 {
  font-size: 1.25vw;
  color: #4000FF;
  font-weight: bold;
  letter-spacing: .25em;
  margin-top: 0;
  margin-bottom: 1.75em;
}

.Plans .how-we-work .row p {
  margin-top: 0;
  padding: 0 4%;
  font-size: .85vw;
  font-weight: normal;
}

.Plans .features {
  margin-top: 2.75em;
  padding-bottom: .5em;
  min-height: 13.5vw;
  margin-right: 3vw;
  margin-left: 3vw;
}

.Plans .features .col-md-4 h5 {
  margin-right: 7.75vw;
  font-size: 1.1vw;
}

.Plans .feature-list ul {
  padding-left: 0;
  margin-top: 3em;
}

.Plans .features ul li {
  color: #FFF;
  margin-bottom: 1em;
}

.Plans .features ul li img {
  max-height: 1.85vw;
  margin-right: 1.25vw;
}

.Plans .integrations {
  margin-top: 4em;
  padding-bottom: 19em;
}

.Plans .integrations h2 {
  font-size: 4vw;
}

.Plans .integrations h5 {
  font-size: 1.33vw;
}

.Plans .integrations h3 {
  font-size: 2.1vw;
  margin-top: .5em;
  margin-bottom: .5em;
}

.Plans .integrations p {
  font-size: 1.1vw;
}

@media (max-width: 767.98px) {

  .Plans .welcome .cta {
    margin-top: 2em;
  }

  .Plans .welcome {
    background-position: 58% 0%;
    background-size: cover;
  }

  .Plans .welcome h1 {
    font-size: 11.5vw;
    line-height: 1.25;
  }

  .Plans .welcome .slogan {
    padding-top: .5em;
    padding-bottom: .5em;
  }

  .Plans .welcome .slogan p {
    font-size: 5.5vw;
  }

  .Plans .more {
    padding-top: 3em;
  }

  .Plans .service-plans {
    margin-top: 7em;
    margin-right: 10vw;
    padding-bottom: 0em;
  }

  .Plans .service-plans .row .col-md-6:first-of-type {
    padding-left: 5px;
  }

  .Plans .service-plans h2 {
    line-height: 1.5;
    margin-bottom: 2em;
    font-size: 7.5vw;
  }

  .Plans .detect-decline {
    border-left: 0;
    margin-left: 0;
  }

  .Plans .detect-decline .col-7 div {
    border-left: 0;
    border-bottom: 0;
    margin-right: 0;
    padding-left: 1vw;
  }

  .Plans .detect-decline .col-7 div h2 {
    font-size: 4.5vw;
    line-height: 1 !important;
    margin-bottom: .25em;
  }

  .Plans .detect-decline .col-7 div h2:last-of-type {
    background: none;
    margin-right: 0;
    top: 0;
    left: 0;
  }

  .Plans .detect-decline .col-5 {
    padding-right: 7vw;
  }

  .Plans .detect-decline .col-5 h3:first-child {
    font-size: 2.75vw;
  }

  .Plans .detect-decline .col-5 p {
    font-size: 1.35vw;
    padding: 0 3vw;
    margin-top: 1.5em;
  }

  .Plans .detect-decline .col-7 p {
    font-size: 1.75vw;
    margin-right: 0;
  }

  .Plans .detect-decline .col-5 hr {
    margin-bottom: .2em;
  }

  .Plans .detect-decline .col-md-11 .col-md-6:first-of-type {
    border-left: 1px solid;
    margin-left: 12.5vw;
  }

  .Plans .detect-decline .col-md-6:first-child h3 {
    font-size: 7vw;
    padding-left: 10vw;
  }

  .Plans h4 {
    margin-bottom: 2em;
  }

  .Plans .service-plans {
    padding-top: 2em;
  }

  .Plans .welcome .service-plans p {
    line-height: 1.75;
    margin-right: 10em;
    font-size: 3.75vw;
  }

  .Plans .service-plans h2 span:first-of-type {
    display: inline;
  }

  .Plans #plans-list {
    top: -4em;
  }

  .Plans .three-tier {
    background-position: -53vw 0%;
    background-size: 152%;
    padding-bottom: 90em;
    padding-right: 3vw;
  }

  .Plans .three-tier .row .col-4 {
    margin-top: 8em;
  }

  .Plans .three-tier .right a {
    font-size: 2.5vw;
  }

  .Plans .three-tier .right a img.right-arrow {
    max-height: 10px;
  }

  .Plans .how-we-work {
    margin-top: 10em;
  }

  .Plans .how-we-work .manual,
  .Plans .how-we-work .platform,
  .Plans .how-we-work .api {
    background-size: 25%;
  }

  .Plans .how-we-work .manual h3,
  .Plans .how-we-work .platform h3,
  .Plans .how-we-work .api h3 {
    background-color: #14123d;
    margin-bottom: 0;
    margin-top: 3em;
  }

  .Plans .how-we-work .row h4 {
    font-size: 3vw;
    margin-top: .5em;
    margin-bottom: 1.5em;
  }

  .Plans .detect-decline .col-md-6:last-child p {
    padding: 0 13vw;
  }

  .Plans .detect-decline .col-md-6:first-child h3:first-child,
  .Plans .detect-decline .col-md-6:last-child h3:first-child {
    font-size: 5vw;
  }

  .Plans .detect-decline .col-md-6:last-child p {
    font-size: 3.25vw;
    padding-lefT: 11vw;
  }

  .Plans .how-we-work h2 {
    font-size: 9vw;
  }
  
  .Plans .how-we-work h3 {
    font-size: 8vw;
  }

  .Plans .how-we-work h5 {
    margin-bottom: 2.5em;
  }

  .Plans .how-we-work .row .row .col-md-4 {
    padding-top: 0;
    margin-bottom: 10em;
  }

  .Plans .how-we-work .row .row h4 {
    background-color: #14123d;
    font-size: 3.5vw;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: .5em;
    padding-bottom: 2.5em;
  }

  .Plans .how-we-work .row .row p {
    background-color: #14123d;
    font-size: 3vw;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .Plans .how-we-work .row p {
    font-size: 2.5vw;
    padding: 0 15%;
  }

  .Plans .how-we-work p {
    font-size: 3vw;
    padding: 0 10%;
  }

  .Plans .feature-list .col-md-4 {
    padding-top: 8em;
  }

  .Plans .feature-list ul {
    margin-top: 0;
    margin-bottom: 0;
    text-align: right;
  }

  .Plans .features ul li {
    font-size: 3.75vw;
    margin-bottom: .65em;
  }

  .Plans .features ul li img {
    float: right;
    max-height: 6vw;
    margin-left: 3.5vw;
  }

  .Plans .features {
    margin-top: 12.5em;
  }

  .Plans .features .col-md-4 h5 {
    font-size: 3vw;
  }

  .Plans .integrations {
    margin-top: 7.5em;
  }

  .Plans .integrations h2 {
    font-size: 6vw;
    margin-bottom: 1em;
  }

  .Plans .integrations h5 {
    font-size: 3.65vw;
  }

  .Plans .integrations h3 {
    font-size: 3.35vw;
  }

  .Plans .integrations p {
    font-size: 1.85vw;
  }

  .Plans .get-started {
    padding-top: 7.5em;
    padding-bottom: 15em;
  }

}
