body {
  background-color: #13133b;
}

h1 {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  font-size: 7.5vw;
  line-height: 8.25vw;
}

h2 {
  font-family: 'GolanoGrotesque-Heavy', Arial, sans-serif;
  font-size: 4.4vw;
  line-height: 1.2;
}

h3 {
  font-family: 'GolanoGrotesque-Bold', Arial, sans-serif;
  font-size: 1.75vw;
  line-height: 1.65;
}

h4 {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  font-size: 1.15vw;
  text-transform: uppercase;
  word-spacing: .2vw;
  letter-spacing: .2em;
  margin-bottom: 2em;
}

h4.small {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: .9vw;
}

h5 {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: 1.275vw;
}

h6 {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  text-transform: uppercase;
  font-size: .8vw;
  line-height: .8vw;
}

p {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.15vw;
}

blockquote {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
}

a {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
}

li {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  list-style-type: none;
}

article {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 1vw;
  color: white;
  padding: 0 7.5%;
}

article:first-of-type {
  padding-top: 5em;
  font-size: 1rem;
}

a:hover {
  color: inherit;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.App {
  text-align: center;
}

.container-full-width {
  overflow-x: hidden;
  overflow-y: auto;
}

.navbar {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  height: 6em;
  padding: 0 0 0 7.5%;
  background: linear-gradient(to right, rgba(20, 20, 75, 0.95), rgb(33, 13, 141, 0.95));
}

.navbar-brand img {
  max-height: 3vw;
}

.navbar .mob-menu {
  display: none;
}

.menu .nav-item a {
  color: #fff;
  font-size: 1vw;
  letter-spacing: .05em;
  text-decoration: none;
  margin: 0 1.15vw;
  border-bottom: 1px solid transparent;
  padding: .5vw .1vw;
}

.menu .nav-item a:hover {
  color: #00FF99;
}

.menu .nav-item a.active {
  color: #00FF99;
  border-bottom: 1px solid #00FF99;
  border-bottom-width: 80%;
}

.navbar .nav-buttons .btn {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: 1vw;
  letter-spacing: .05em;
  padding: 10px 40px;
  text-transform: none;
  border-width: .15rem;
}

.navbar .nav-buttons .btn:first-of-type {
  border-color: #4000FF;
}

.navbar .nav-buttons .btn:first-of-type:hover,
.navbar .nav-buttons .btn:first-of-type:active {
  background-color: #4000FF !important;
  color: #00FF99 !important;
}

.navbar .nav-buttons .btn:last-of-type {
  padding: 10px 20px;
  margin-left: 1.5vw;
  color: #fff;
}

.navbar .nav-buttons .btn:last-of-type:hover,
.navbar .nav-buttons .btn:last-of-type:active {
  background-color: #00FF99 !important;
  border-color: #00FF99 !important;
  color: #000355 !important;
}

.navbar .nav-buttons {
  min-width: 290px;
  margin-right: 2vw;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.green {
  color: #00FF99;
}

.purple {
  color: #4000FF;
}

.btn {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: 1.125vw;
  letter-spacing: .15vw;
  text-transform: uppercase;
  background: none;
  border-color: #00FF99;
  border-width: 3px;
  max-width: 25.5vw;
  border-radius: 5rem;
  padding: 1.85vw 3.65vw;
}

.btn.medium {
  padding: 1.5vw 2.5vw;
  font-size: 1vw;
  border-width: 2px;
}

.btn.smaller {
  padding: 1.125vw 2.5vw;
  text-transform: none;
  font-size: .9vw;
  border-width: 2px;
  letter-spacing: .09vw;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #4000FF !important;
  color: #00FF99 !important;
  border-color: #4000FF !important;
}

.tagline h4 {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  margin-top: 5.75em;
  margin-bottom: 2.25em;
  padding-left: 5vw;
}

.cta {
  display: block;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding-left: 6.5%;
  padding-top: 4.5%;
  padding-bottom: 3.65%;
  margin-left: 30%;
  margin-right: 3.25em;
  transition: all .3s ease;
  max-width: 16.65vw;
}

.cta:hover {
  text-decoration: none;
}

.cta h6 {
  margin: 0;
  font-weight: bold;
}

.cta span {
  display: block;
}

.welcome .cta span {
  font-family: 'GolanoGrotesque-Light', Arial, sans-serif;
  font-size: 1.1vw;
}

.more {
  padding-top: 4em;
  padding-bottom: 4em;
}

.more img {
  margin-top: .75em;
  border-radius: 2rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.more a {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.15vw;
  color: #fff;
  text-decoration: none;
}

.more a:hover img {
  box-shadow: 0 0 20px .1rem rgb(0, 255, 150, 0.5) inset, 0 0 10px 0.35rem rgb(0, 255, 150, 0.5);
}

a img.down-arrow {
  margin-top: .75em;
  border-radius: 2rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

a img.down-arrow:hover {
  box-shadow: 0 0 20px .1rem rgb(0, 255, 150, 0.5) inset, 0 0 10px 0.35rem rgb(0, 255, 150, 0.5);
}

.card {
  background: none;
  border: 0;
  color: #fff;
  max-width: 250px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
}

.card-text {
  text-align: right;
}

footer {
  color: #fff;
  height: 476px;
  border-top: 1px solid #484646;
}

footer .footer-upper {
  padding: 60px 7.5% 40px 7.5%;
}

footer .footer-upper .col-md-5:first-child {
  margin-top: 2.25em;
}

footer .footer-upper .col-md-5 img {
  margin-left: 2vw;
}

footer .footer-lower {
  margin: 0;
  padding: 50px 7.5% 0 7.5%;
  border-top: 4px solid #191B40;
}

footer .footer-lower p {
  font-size: .835vw;
  display: inline;
}

footer .footer-lower .col-md-7 {
  padding-left: 0;
}

footer .footer-lower .col-md-5{
  padding-right: 0;
}

footer .footer-lower a {
  margin-left: 5vw;
  font-size: .835vw;
}

footer .footer-lower .right a {
  margin-left: 0;
}

footer .footer-lower .right a img {
  padding-left: 20px;
}

footer .FooterForm {
  width: 16vw;
  float: right;
  height: 4.75vw;
  margin-right: .6vw;
}

footer h3 {
  font-size: 3vw;
  font-family: 'GolanoGrotesque-Bold', Arial, sans-serif;
  font-weight: normal;
  margin-bottom: .85em;
}

footer ul {
  padding-left: 0;
}

footer ul li {
  list-style-type: none;
  padding: .85em;
}

footer ul li:first-child {
  padding-bottom: 1.75em;
}

footer li a {
  letter-spacing: .1em;
  font-size: .94vw;
  font-family: 'GolanoGrotesque', Arial, sans-serif;
}

footer strong {
  font-size: 1vw;
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: normal;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #fff;
}

footer input.email {
  float: left;
  background-color: transparent;
  border-width: 1px;
  border-radius: 5rem;
  border-style: hidden;
  margin: .65em .75vw .6em 1.5vw;
  color: #fff;
  padding: 1.3vw .35vw;
  display: block;
  outline: none;
  box-shadow: none;
  font-size: .8vw;
  max-width: 9.70vw;
}

footer input:focus {
  background-color: transparent;
}

footer input:focus::placeholder {
  color: #fff;
}

footer input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4000FF;
  opacity: 1; /* Firefox */
}

footer .FooterForm .submit-envelope {
  position: relative;
  left: -.75vw;
  width: 3.3vw;
  height: 3.3vw;
  padding: 0;
  margin-right: 0;
  top: .75vw;
  outline: none;
}

footer .FooterForm .submit-envelope:hover {
  cursor: pointer;
}

.flex-end {
  display: flex;
  align-self: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.mob-only {
  display: none;
}

.desk-only {
  display: inherit;
}

/* "wall of text" */
.wot {
  margin: 10em 10%;
  color: #fff;
}

.Security .col-md-12 ul li {
  font-size: 1.3rem;
}

.PrivacyPolicy p,
.Terms p {
  font-size: 1rem;
}

.Security p {
  font-size: 1.1rem;
}

.wot h4 {
  margin-top: 2em;
  margin-bottom: 1em;
  letter-spacing: normal;
}

.Careers object {
  margin-top: 2em;
  margin-bottom: 4em;
}

.Careers .wot {
  margin-bottom: 4em;
}

.PrivacyPolicy table {
  margin-top: 1em;
  margin-bottom: 1em;
}

.PrivacyPolicy th {
  text-align: center;
}

.PrivacyPolicy th,
.PrivacyPolicy td {
  border: 1px solid #ddd;
  padding: .75rem;
}

.wot ul li {
  list-style-type: disc;
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-weight: normal;
}

.wot ul li strong {
  font-family: 'GolanoGrotesqueMedium', Arial, sans-serif;
}

@media (max-width: 767.98px) {

  body {
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h1 {
    font-size: 12vw;
    line-height: 1.05;
  }

  h2 {
    font-size: 7vw;
    line-height: 1.15;
  }

  h3 {
    font-size: 8vw;
    line-height: 1;
  }

  h4 {
    font-size: 2.5vw;
    font-weight: normal;
  }

  h4.small {
    font-size: 2vw;
  }

  h5 {
    font-size: 3.35vw;
  }

  h6 {
    font-size: 2vw;
  }

  p {
    font-size: 4vw;
    line-height: 1.75;
  }

  article {
    padding: 0 6vw;
  }
  
  /* fixes a weird right 
     jog when centering */
  .center .col-md-5,
  .center .col-md-6,
  .center .col-md-7,
  .center.col-md-5,
  .center.col-md-6,
  .center.col-md-7,
  .col-md-5 .center,
  .col-md-6 .center,
  .col-md-7 .center,
  .center .col-6 {
    padding-right: 0;
  }

  .tagline h4 {
    font-size: 3.25vw;
    font-weight: bold;
    margin-top: 3em;
  }

  .navbar {
    height: 5em;
  }

  .navbar .nav-buttons .btn {
    font-size: 2.875vw;
    padding: 8px 26px;
    border-width: .15rem;
  }

  .navbar .menu, 
  .navbar .nav-buttons .btn:last-of-type {
    display: none;
  }

  .navbar .mob-menu .btn-primary,
  .navbar .mob-menu .btn-primary:focus,
  .navbar .mob-menu .btn-primary:active,
  .navbar .mob-menu button {
    background: none !important;
    border: 0 !important;
    box-shadow: none;
  }

  .navbar .mob-menu .btn {
    display: block;
    padding: 0;
  }

  .navbar .mob-menu img {
    margin-right: 5vw;
  }

  .navbar .mob-menu {
    margin-left: .3em;
    display: inline-block;
  }

  .navbar .nav-link {
    padding: 0;
  }

  .navbar .mob-menu .dropdown-toggle::after {
    display: none;
  }

  .navbar-brand img {
    max-height: 35px;
  }

  .navbar .nav-buttons {
    min-width: 0px;
    margin-left: 15vw;
  }

  .navbar .dropdown-menu {
    border: none;
    border-radius: 0;
  }

  .navbar .dropdown-menu-right {
    position: fixed;
    top: 0;
    width: 100vw;
    text-align: right;
    height: 100%;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .navbar .dropdown-menu-right img {
    float: left;
    padding-left: 3.5vw;
    margin-top: 0vw;
  }

  .navbar .dropdown-menu-right a:first-of-type {
    margin-top: 1.25em;
  }

  .navbar .dropdown-menu .dropdown-items {
    padding-left: 15vw;
    padding-right: 6vw;
  }

  .navbar .dropdown-menu-right a {
    color: #fff;
    font-size: 10vw;
    font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  }

  .navbar .dropdown-menu .login, 
  .navbar .dropdown-menu .request {
    position: fixed;
    bottom: 0;
    padding: 2em 0;
    display: inline-block;
    width: 50%;
    text-align: center;
    font-size: 3vw;
    vertical-align: middle;
    font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  }

  .navbar .dropdown-menu .login {
    left: 0;
    background-color: #00FF99;
    color: #4000FF;
  }

  .navbar .dropdown-menu .request {
    right: 0;
    background-color: #4000FF;
    color: #fff;
  }

  .navbar .dropdown-divider {
    margin: .25rem 0;
    border-color: #00FF99;
  }

  .navbar .dropdown-item:focus, 
  .navbar .dropdown-item:hover,
  .navbar .dropdown-item:active,
  .navbar .dropdown-item .active {
    background-color: #00FF99;
    color: #4000FF;
    border-width: 12px;
    border-radius: 5rem;
  }

  .btn {
    font-size: 2.75vw;
    padding: 4.5vw 9vw;
    border-width: 2px;
    max-width: none;
  }

  .btn.medium {
    font-size: 2.65vw;
    padding: 4vw 12vw;
    border-width: 3px;
  }

  .btn.smaller {
    font-size: 2.5vw;
    padding: 3.5vw 7vw;
    max-width: none;
    border-width: 1px;
  }

  .cta {
    padding-left: 6.5%;
    padding-top: 4.5%;
    padding-bottom: 3.65%;
    margin-right: 3.75em;
    margin-left: 8.25em;
    max-width: none;
  }

  .cta h6 {
    font-size: 2.25vw;
    margin-bottom: .8em;
  }

  .welcome .cta span {
    font-size: 3.5vw;
  }

  .more {
    padding-top: 6.35em;
    padding-bottom: 5.35em;
  }

  .more a {
    font-size: 3.5vw;
  }

  img.down-arrow {
    max-height: 36px;
  }

  img.right-arrow {
    max-height: 20px;
  }

  footer {
    height: auto;
  }

  footer .footer-upper .col-md-5:first-child {
    margin-top: 1.25em;
  }

  footer h3 {
    font-size: 6vw;
  }

  footer .footer-upper {
    padding: 10px 7.5% 40px 7.5%;
    margin-left: 0;
    margin-right: 0;
  }

  footer .col-md-5,
  footer .right {
    text-align: center;
  }

  footer img {
    max-height: 35px;
  }

  footer ul {
    margin-bottom: .5em;
  }

  footer ul li {
    list-style-type: none;
    padding: 0;
  }

  footer ul li:first-child {
    margin-top: 1.25em;
    padding-bottom: 0;
  }

  footer li a {
    letter-spacing: .1em;
    font-size: 3vw;
    font-family: 'GolanoGrotesque', Arial, sans-serif;
  }

  footer .FooterForm {
    width: 61.75vw;
    height: 4.75em;
    display: flex;
    margin-right: 0;
    margin-left: 0;
    float: none;
  }

  footer .FooterForm .submit-envelope {
    left: 1.25vw;
    height: 12.3vw;
    width: auto;
    top: .75em;
  }

  footer input.email {
    font-size: 2.85vw;
    margin: 2em 0 1.5em 5.5vw;
    max-width: 40vw;
    width: 40vw;
  }

  footer strong {
    font-size: 3vw;
    font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-weight: normal;
  }

  footer .footer-lower {
    clear: both;
    padding: 2em 7.5% 2em 7.5%;
  }

  footer .footer-lower p {
    display: block;
    font-size: 3vw;
    text-align: center;
  }

  footer .footer-lower a {
    display: block;
    margin-bottom: 1em;
    font-size: 3vw;
    text-align: center;
  }

  .newsletter-signup {
    display: flex;
    justify-content: center;
  }

  .desk-only {
    display: none;
  }

  .mob-only {
    display: inherit;
  }

}
