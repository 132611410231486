@font-face {
  font-family: 'GolanoGrotesque';
  src: url('fonts/GalanoGrotesque.woff2') format('woff2'),
       url('fonts/GalanoGrotesque.woff') format('woff'),
       url('fonts/GalanoGrotesque.ttf') format('truetype');
}

@font-face {
  font-family: 'GolanoGrotesque-Light';
  src: url('fonts/GalanoGrotesque-Light.woff2') format('woff2'),
       url('fonts/GalanoGrotesque-Light.woff') format('woff'),
       url('fonts/GalanoGrotesque-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'GolanoGrotesque-Medium';
  src: url('fonts/GalanoGrotesque-Medium.woff2') format('woff2'),
       url('fonts/GalanoGrotesque-Medium.woff') format('woff'),
       url('fonts/GalanoGrotesque-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GolanoGrotesque-Heavy';
  src: url('fonts/GalanoGrotesque-Heavy.woff2') format('woff2'),
       url('fonts/GalanoGrotesque-Heavy.woff') format('woff'),
       url('fonts/GalanoGrotesque-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'GolanoGrotesque-Bold';
  src: url('fonts/GalanoGrotesque-Bold.woff2') format('woff2'),
       url('fonts/GalanoGrotesque-Bold.woff') format('woff'),
       url('fonts/GalanoGrotesque-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GolanoGrotesque-SemiBold';
  src: url('fonts/GalanoGrotesque-SemiBold.woff2') format('woff2'),
       url('fonts/GalanoGrotesque-SemiBold.woff') format('woff'),
       url('fonts/GalanoGrotesque-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'GolanoGrotesque-SemiBoldItalic';
  src: url('fonts/GalanoGrotesque-SemiBoldItalic.woff2') format('woff2'),
       url('fonts/GalanoGrotesque-SemiBoldItalic.woff') format('woff'),
       url('fonts/GalanoGrotesque-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'GolanoGrotesque-ExtraBold';
  src: url('fonts/GalanoGrotesque-ExtraBold.woff2') format('woff2'),
       url('fonts/GalanoGrotesque-ExtraBold.woff') format('woff'), 
       url('fonts/GalanoGrotesque-ExtraBold.ttf') format('truetype');
}
