.Customers .welcome h1 {
  line-height: 1.1;
  margin-left: 7.5vw;
  font-size: 7.25vw;
}

.Customers .welcome p {
  margin-left: 7.75vw;
}

.Customers .cta {
  margin-left: 9vw;
}

.Customers .featured {
  padding-top: 14em;
  padding-bottom: 9em;
}

.Customers .featured h1 {
  font-size: 6.5vw;
  margin-left: 0;
}

.Customers .featured h4 {
  margin-bottom: 1em;
}

.Customers .featured a {
  font-size: 1.15vw;
}

.Customers .featured img {
  max-height: 21px;
}

.Customers .welcome a {
  color: #fff;
}

.Customers blockquote {
  font-size: 1.75vw;
}

.Customers img.quote {
  max-height: 32px;
}

.Customers .case-studies img.quote:first-of-type {
  position: relative;
  top: -1em;
}

.Customers .case-studies img.quote:last-of-type {
  position: relative;
  top: .5em;
}

.Customers .case-studies {
  padding-top: 28em;
}

.Customers .case-studies h5 {
  font-size: 1.1vw;
  margin-top: 4em;
}

.Customers .case-studies p {
  font-size: .85vw;
  margin-bottom: 0;
}

.Customers .case-studies .studies p {
  font-size: 1.25vw;
  margin: 1em 40vw 2em 7vw;
}

.Customers .study:first-of-type {
  margin-left: 7vw;
}

.Customers .study {
  display: inline-block;
  max-width: 23vw;
}

.Customers .study:hover {
  filter: sepia(25%) brightness(43%) hue-rotate(95deg) grayscale(33%);
}

.Customers .study img {
  position: relative;
}

.Customers .study .img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.Customers .featured p {
  margin-left: 17vw;
  font-size: 1.25vw;
  line-height: 2;
}

.Customers .testimonials {
  margin-top: 7em;
}

.Customers .testimonials h2 {
  margin-bottom: .75vw;
}

.Customers .test-rows .col-md-4 {
  padding: 3em 5em;
}

.Customers .test-rows blockquote {
  border-top: 3px solid #fff;
  padding: 1.75em 0;
  color: #fff;
  font-size: 1.35vw;
  min-height: 14.25em;
}

.Customers .test-rows p {
  margin-left: 2vw;
  color: #fff;
}

/* .Customers .testimonials .col-md-4 {
  padding: 3em 5em;
}

.Customers .testimonials blockquote {
  border-top: 3px solid #4000FF;
  padding: 3em 0;
  color: #4000FF;
  font-size: 1.35vw;
  min-height: 16.5em;
}

.Customers .testimonials p {
  margin-left: 2vw;
  color: #4000FF;
} */

.Customers p:first-of-type {
  font-weight: bold;
}

.Customers .sell-sell {
  margin-top: 4vw;
}

.Customers .sell-sell h3 {
  font-size: 3.5vw;
  line-height: 1.1;
  margin: 25px 0;
}

.Customers .sell-sell h4 {
  font-size: .95vw;
}

.Customers .sell-sell p {
  margin: 30px 0;
}

.Customers .keep-jamming {
  padding-bottom: 3em;
  padding-top: 5em;
}

.Customers .keep-jamming p {
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  font-size: 1.15vw;
}

.Customers .world {
  margin-top: 3vw;
  padding-bottom: 22vw;
}

.Customers .world h5 {
  font-size: 1.1vw;
}

.Customers .world .col-md-6:first-of-type h5:first-of-type {
  margin-top: 8vw;
  margin-bottom: .25vw;
}

.Customers .world .col-md-6:last-of-type h5:first-of-type {
  margin-top: 11vw;
  margin-right: 18vw;
  margin-bottom: 0;
}

.Customers .world img {
  max-height: 65px;
}

.Customers .world .col-md-6:first-of-type img {
  position: relative;
  left: -1vw;
  top: -1vw;
}

.Customers .world .col-md-6:last-of-type img {
  position: relative;
  margin-right: 20vw;
  top: -0.5vw;
}

.Customers .keep-jamming h2 {
  font-size: 3vw;
  margin-right: 9.75vw;
}

.Customers .get-started {
  padding-bottom: 26vw;
}

.Customers .get-started ul {
  position: relative;
  top: -1vw;
  padding-left: 0;
}

.Customers .get-started ul li {
  margin-bottom: 1.5vw;
}

.Customers .get-started ul li img {
  padding-right: 1.5vw;
  max-height: 4.40vw;
}

.Customers .get-started h3 {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  font-size: 72px;
  line-height: 74px;
  padding-left: 300px;
  padding-right: 300px;
  margin-top: 100px;
  margin-bottom: 75px;
}

@media (max-width: 767.98px) {

  .Customers .welcome {
    background-position: bottom;
  }
  
  .Customers .get-started h3 {
    font-size: 14vw;
  }

  .Customers .slogan {
    margin-top: 3em;
  }

  .Customers .more {
    padding-top: 3.35em;
  }

  .Customers .featured {
    padding-top: 6em;
    padding-bottom: 4em;
  }

  .Customers .featured h1 {
    font-size: 7.25vw;
  }

  .Customers .featured p {
    font-size: 3.25vw;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 36vw;
  }

  .Customers .featured a {
    font-size: 3.5vw;
  }

  .Customers .case-studies {
    background-size: contain;
    padding-right: 5vw;
    padding-top: 27em;
    margin-bottom: 1em;
  }

  .Customers .case-studies .col-4 {
    padding-left: 0;
    padding-right: 0;
  }

  .Customers blockquote,
  .Customers .test-rows blockquote {
    font-size: 2.35vw;
    margin-bottom: 1em;
  }

  .Customers img.quote {
    max-height: 3.25vw;
  }

  .Customers .case-studies h5 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2vw;
  }

  .Customers .case-studies p {
    font-size: 1.6vw;
  }

  .Customers .case-studies .test-rows p {
    font-size: 2.3vw;
    margin-left: 0;
  }

  .Customers .testimonials {
    margin-top: 7em;
  }

  .Customers .test-rows {
    margin-top: 4em;
    margin-bottom: -11em;
  }

  .Customers .test-rows .col-md-4:first-of-type {
    margin-left: 0;
    margin-right: 54%;
    top: 0;
    text-align: left;
  }

  .Customers .test-rows .col-md-4 {
    margin-left: 54%;
    margin-right: 0;
    position: relative;
    top: -16em;
    padding: 3em 2.5em;
    text-align: right;
  }

  .Customers .test-rows .col-md-4:last-of-type {
    margin-left: 9%;
    margin-right: 45%;
    padding-top: 0;
    text-align: left;
  }

  .Customers .test-rows blockquote {
    border-top-width: 2px;
    margin-bottom: 0;
    min-height: unset;
  }

  .Customers .sell-sell h3 {
    font-size: 6vw;
  }

  .Customers .sell-sell {
    margin-top: 10em;
  }

  .Customers .sell-sell p {
    margin: 1.5em 0;
    font-size: 3vw;
  }

  .Customers .world {
    padding-bottom: 2vw;
  }

  .Customers .get-started {
    padding-top: 11em;
    padding-bottom: 5em;
  }

  .Customers .get-started ul li {
    font-size: 1.05vw;
  }

  .Customers .keep-jamming h2 {
    font-size: 5vw;
    margin-bottom: 1em;
  }

  .Customers .keep-jamming p {
    margin-bottom: 2em;
    font-size: 2.65vw;
  }

  .Customers .get-started ul li {
    font-size: 2.5vw;
  }

  .Customers .get-started .col-7 .col-md-6:first-of-type ul {
    margin-bottom: 0;
  }

}

@media (min-width: 1024px) {

  .Customers .sell-sell .btn,
  .Customers .keep-jamming .btn {
    font-size: .85vw;
    padding: 1.25vw 1.25vw;
  }

}

@media (max-width: 576px) {

  .Customers #case-studies blockquote {
    font-size: 2.2vw !important;
  }

}
