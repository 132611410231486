.About .welcome h1 {
  padding-left: 5%;
}

.About .slogan .cta {
  display: block;
  margin-left: 80px;
  margin-top: 150px;
  margin-bottom: 40px;
}

.About .slogan p {
  position: relative;
  top: 3.75em;
  left: -2.5vw;
  font-size: 1.5vw;
  padding-right: 4vw;
}

.About .how-we-work {
  margin-top: 12em;
}

.About .how-we-work h2 {
  font-size: 4vw;
  margin-bottom: .35em;
}

.About .how-we-work h5 {
  font-family: 'GolanoGrotesque';
  font-size: 1.65vw;
  padding-left: 11%;
  padding-right: 11%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.About .how-we-work p {
  font-size: 1.1vw;
  padding: 0 23%;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.About .connect-dots {
  padding-top: 7em;
  padding-bottom: 3em;
  margin-top: 4em;
  padding-right: 5%;
}

.About .connect-dots h2 {
  font-size: 6vw;
  margin-left: 3vw;
  line-height: 1;
}

.About .connect-dots h4 {
  margin-top: 1em;
}

.About .connect-dots h2 span:first-of-type {
  display: block;
}

.About .connect-dots h3 {
  font-size: 2.35vw;
  font-family: 'GolanoGrotesque-Light', Arial, sans-serif;
  text-align: right;
  line-height: 1.25;
}

.About .connect-dots h3.green {
  font-size: 3vw;
  margin-top: .75em;
}

.About .connect-dots h3:first-of-type {
  margin-left: 13vw;
  margin-top: .5em;
}

.About .connect-dots h3 strong {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
}

.About .connect-dots h3:last-of-type {
  text-align: right;
  padding-left: 12vw;
}

.About .connect-dots .col-md-6:first-of-type p {
  font-size: 1.1vw;
  margin-top: 3em;
  margin-left: 3vw;
  margin-bottom: 5em;
  letter-spacing: .1em;
}

.About .connect-dots .col-md-6:last-of-type p {
  padding-right: 2vw;
  margin-top: 5em;
  margin-bottom: 2em;
  font-size: 1.4vw;
}

.About .connect-dots .col-md-6:last-of-type .btn {
  margin-top: 0;
  margin-right: 5.75vw;
}

.About .connect-dots .btn-primary {
  margin-bottom: 10em;
}

.About .fraud-review {
  padding-top: 2em;
}

.About .fraud-review h2 {
  font-size: 4vw;
}

.About .fraud-services {
  margin-top: 6em;
  margin-bottom: 6em;
}

.About .fraud-services .row:first-of-type {
  margin-bottom: 4em;
}

.About .fraud-services .col-md-6 {
  padding-left: 3.5vw;
}

.About .fraud-services .col-md-6 h3 {
  font-size: 2.35vw;
  padding-top: 1.5em;
  line-height: 1.2;
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
}

.About .fraud-services .col-md-5 ul {
  margin-top: 2em;
  padding-left: 0;
  margin-right: 2vw;
  margin-left: 4.5vw;
}

.About .fraud-services .col-md-5 ul li {
  display: flex;
  font-size: 1.1vw;
  text-transform: uppercase;
  letter-spacing: .15vw;
  min-width: 25vw;
  margin-bottom: 2em;
  justify-content: flex-end;
}

.About .fraud-services .col-md-5 ul li img {
  max-height: 2.25vw;
  margin-left: 2.5vw;
  align-self: center;
}

.About .fraud-services .col-md-6 p {
  margin-top: 2em;
  font-size: 1.25vw;
}

.About .fraud-services .col-md-12 {
  padding-right: 1vw;
}

.About .fraud-services .col-md-12 p {
  margin-bottom: 3em;
}

.About .fraud-review .col-md-12 {
  padding-left: 3.5vw;
}

.About .fraud-review .col-md-12 h2,
.About .fraud-review .col-md-12 p {
  margin-left: 4vw;
}

.About .fraud-review h2 {
  font-size: 4.5vw;
}

.About .fraud-review .col-md-12 p {
  margin-top: 1em;
  margin-bottom: 5em;
  padding-right: 8vw;
  font-size: 3vw;
  line-height: 1.25;
}

.About .fraud-review .center.col-md-5 {
  margin-top: 5em;
  padding-left: 8.5vw;
  padding-right: 3vw;
}

.About .fraud-review .center.col-md-5 p {
  font-size: 1.35vw;
  margin-bottom: .5em;
}

.About .fraud-review .col-md-7 {
  padding-left: 3.5vw;
  padding-right: 2vw;
  padding-bottom: 5em;
}

.About .fraud-review .col-md-7 a {
  margin-top: 1em;
  color: #fff;
  font-size: 1.15vw;
  text-decoration: none;
  border-bottom: 1px transparent;
}

.About .fraud-review .col-md-7 a:hover {
  border-bottom: 1px solid #fff;
}

.About .fraud-review .col-md-7 a img {
  max-height: .5vw;
  transform: rotate(90deg);
}

.About .fraud-review .col-md-7 p {
  margin-top: 1.85em;
  margin-bottom: 2.5em;
  padding-right: 9vw;
  font-size: 1.35vw;
}

.About .fraud-review .center .btn.smaller {
  margin-top: 2em;
  margin-bottom: 8em;
}

.About .fraud-review .center h4 {
  font-family: 'GolanoGrotesque-Bold', Arial, sans-serif;
  margin-top: .75em;
  margin-bottom: .5em;
  font-size: 2.15vw;
  letter-spacing: normal;
  text-transform: none;
  font-weight: bold;
}

.About .get-started {
  margin-top: 6em;
  margin-bottom: 7em;
}

.About .welcome .cta {
  margin-top: 16.5em;
  margin-left: 5em; 
}

.About .cta {
  margin-right: 8.25em
}

.About .what-we-bring {
  margin-top: 5em;
  margin-bottom: 0;
}

.About .our-difference {
  padding-top: 22em;
  padding-bottom: 26em;
}

.About .our-difference .col-md-7 h2 {
  margin-left: 7vw;
}

.About .our-difference .col-md-7 p {
  margin-left: 7vw;
  margin-right: 10vw;
}

.About .our-difference .col-md-5 {
  margin-top: 2em;
}

.About .our-difference .col-md-5 h3 {
  text-align: center;
  margin-left: 6vw;
}

.About .our-difference .col-md-5 ul {
  margin-right: 13vw;
  margin-top: 2em;
}

.About .our-difference .col-md-5 ul li {
  text-align: right;
  text-transform: uppercase;
  margin-bottom: 2em;
}

.About .our-difference .col-md-5 ul li img {
  max-height: 12vw;
  padding-left: 2em;
}

.About .making-sense h2 {
  margin-right: 28vw;
  margin-left: 3vw;
}

.About .different {
  margin-top: 8em;
}

.About .different .col-md-5 {
  padding-right: 5vw;
}

.About .different h2 {
  font-size: 7vw;
}

.About .different h3 {
  font-size: 2.5vw;
  line-height: 1.25;
}

.About .different h3:first-of-type {
  margin-top: .5em;
}

.About .what-we-bring h3 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.75vw;
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
}

.About .what-we-bring .col-md-3:first-of-type img {
  margin-top: 1vw;
  max-height: 5vw;
}

.About .what-we-bring img {
  max-height: 6vw;
}

.About .what-we-bring p {
  margin: 0 .75vw 5em .75vw;
  font-size: .8vw;
  text-align: left;
}

.About .making-sense {
  margin-top: 4em;
}

.About .making-sense .col-md-6 hr {
  margin-top: 5em;
  margin-bottom: 2em;
  margin-right: 60%;
  border-top: 1px solid #00FF99;
}

.About .making-sense .col-md-6:first-of-type hr {
  margin-top: 3em;
}

.About .making-sense .col-md-6:first-of-type {
  padding-left: 4vw;
}

.About .making-sense .col-md-6 p {
  margin-right: 10vw;
}

.About .mission {
  margin-top: 7em;
  padding-top: 1em;
  padding-bottom: 2.75em;
}

.About .mission .col-md-5 {
  padding-right: 2.5vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.About .mission h3 {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  font-size: 2.35vw;
  line-height: 1.5;
  padding-right: 7vw;
}

@media (max-width: 767.98px) {

  .About .welcome {
    background-position: left;
    background-size: auto;
  }

  .About .welcome h1 {
    padding-left: 2%;
  }

  .About .get-started {
    margin-top: 8em;
    margin-bottom: 20em;
  }

  .About .how-we-work {
    margin-top: 2em;
    padding-top: 0;
  }

  .About .welcome .cta {
    margin-top: 3.5em;
    margin-bottom: 0;
    margin-left: 7vw;
  }
  
  .About .what-we-bring img {
    max-height: 9vw;
  }

  .About .what-we-bring h3 {
    font-size: 3vw;
  }

  .About .what-we-bring p {
    margin: 0 4vw 3em 4vw;
    font-size: 2vw;
  }

  .About .slogan p {
    font-size: 4.25vw;
    margin-top: 3em;
    margin-bottom: 3em;
    padding-bottom: 0;
    position: unset;
    text-align: right;
  }

  .About .more {
    padding-top: 1.25em;
    margin-bottom: 8em;
    padding-bottom: 0;
  }

  .About .how-we-work h4 {
    margin-bottom: .5em;
  }

  .About .how-we-work h2 {
    font-size: 6vw;
  }

  .About .how-we-work h5 {
    font-size: 3.5vw;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }

  .About .how-we-work p {
    font-size: 2vw;
    padding: 0;
  }

  .About .connect-dots h2 {
    margin-right: 22vw;
  }

  .About .connect-dots h2 span:first-of-type {
    display: inline;
  }

  .About .connect-dots .col-md-6:first-of-type p {
    font-size: 3vw;
    margin-left: 4.5vw;
    margin-right: 5vw;
    margin-bottom: 2.5em;
  }

  .About .connect-dots .col-md-6:first-of-type p em {
    display: block;
    margin-top: 1em;
    font-size: 6vw;
  }

  .About .connect-dots .col-md-6:first-of-type p em::first-letter {
    text-transform: capitalize;
  }

  .About .connect-dots h3,
  .About .connect-dots h3.green {
    font-size: 5vw;
  }

  .About .connect-dots h3:first-of-type {
    text-align: right;
  }

  .About .connect-dots h3:last-of-type {
    text-align: left;
    padding-left: 2vw;
  }

  .About .connect-dots h3:last-of-type em {
    display: block;
  }

  .About .connect-dots .col-md-6:last-of-type p {
    font-size: 3.75vw;
    margin-top: 4em;
    margin-bottom: 1em;
    padding-left: 0;
  }

  .About .connect-dots .col-md-6:last-of-type {
    text-align: center;
  }

  .About .connect-dots .col-md-6:last-of-type .btn {
    margin-left: 0;
  }

  .About .fraud-review .col-md-7 a {
    font-size: 3vw;
  }

  .About .fraud-review .col-md-7 a img {
    max-height: 1vw;
  }

  .About .fraud-review {
    padding-top: 15em;
  }

  .About .fraud-review .center .btn.smaller {
    margin-bottom: 2em;
  }

  .About .fraud-services .col-md-6 h3 {
    padding-top: 0;
    font-size: 3.5vw;
  }

  .About .fraud-services .col-md-6 p {
    font-size: 2.75vw;
    margin-right: 22vw;
    margin-top: 1.5em;
    margin-bottom: 0;
  }

  .About .fraud-services .col-md-5 ul {
    padding-left: 0;
    margin-top: 4.5em;
  }

  .About .fraud-services .col-md-5 ul li {
    font-size: 2.75vw;
    justify-content: flex-end;
  }

  .About .fraud-services .col-md-12 p {
    margin-top: 3em;
  }

  .About .fraud-services .col-md-5 ul li img {
    max-height: 4vw;
  }

  .About .fraud-services h3 {
    margin-top: 2.25em;
    font-size: 6vw;
  }

  .About .fraud-review .fraud-protect {
    margin-top: 0em;
    padding-top: 10em;
    padding-bottom: 20em;
    margin-left: -30px;
    margin-right: -30px;
  }

  .About .fraud-review .col-md-7 {
    padding-left: 5vw;
  }

  .About .fraud-review .center.col-md-5 {
    margin-top: 15em;
    padding-right: 0;
    padding-left: 42vw;
    position: relative;
    top: 10em;
  }

  .About .fraud-review .center.col-md-5 p {
    margin-bottom: .75em;
  }

  .About .fraud-review .center h4 {
    font-size: 3vw;
    margin-top: 1.5em;
    margin-bottom: 0;
  }

  .About .fraud-review .col-md-12 h2 {
    font-size: 5.5vw;
  }

  .About .fraud-review .col-md-12 p {
    font-size: 4.5vw;
    text-align: left;
    margin-bottom: 1em;
  }

  .About .fraud-review .center.col-md-5 p {
    font-size: 2.5vw;
  }

  .About .different h3 {
    display: inline;
    font-size: 4vw;
    margin-left: 1.5vw;
  }

  .About .fraud-review .col-md-7 p {
    font-size: 3vw;
  }

  .About .different {
    margin-top: -55em;
  }

  .About .different .col-md-5 {
    margin-top: 32em;
    padding-right: 7vw;
  }

  .About .our-difference .col-md-5 ul li {
    font-size: 3vw;
  }

  .About .our-difference .col-md-5 ul li img {
    max-height: 6vw;
    padding-left: .75em;
  }

  .About .making-sense h4 {
    font-size: 2.15vw;
  }

  .About .making-sense h2 {
    margin-right: 11.5vw;
    margin-bottom: 1.75em;
  }

  .About .making-sense .row {
    margin-bottom: 3em;
  }

  .About .making-sense .col-md-6:first-of-type hr {
    margin-left: 60%;
    margin-right: 0;
  }

  .About .making-sense .col-md-6:first-of-type p {
    text-align: right;
    margin-left: 10vw;
    margin-right: 0;
  }

  .About .making-sense .col-md-6 p {
    margin-bottom: 1em;
  }

  .About .mission {
    margin-top: 15em;
  }

  .About .mission h3 {
    font-size: 6vw;
    text-align: center;
    margin-bottom: 2em;
  }

}

@media (min-width: 1842.98px) {

  .About .fraud-review .center.col-md-5 {
    margin-top: 0;
    padding-left: 7vw;
    padding-right: 5vw;
  }

}
