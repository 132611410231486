.Services .tagline h4 {
  margin-bottom: 1em;
}

.Services .welcome {
  padding-bottom: 16em;
}

.Services .welcome h1 {
  margin-left: 8.5vw;
  margin-top: .4em;
}

.Services .welcome .tagline p {
  font-size: 1.65vw;
  margin-left: 8.5vw;
  margin-top: .65em;
  margin-right: 5.5vw;
}

.Services .welcome .cta {
  margin-left: 40%;
  margin-top: -25%; 
}

.Services .welcome .lm .more {
  padding-top: 3.75em;
}

.Services .fp-guarantee {
  margin-top: 5em;
  padding-left: 12vw;
  padding-right: 12vw;
}

.Services .fp-guarantee h2 span:first-of-type {
  display: block;
}

.Services .fp-guarantee p {
  font-size: 1.65vw;
}

.Services .welcome h2 {
  font-size: 3.85vw;
}

.Services .fp-guarantee p {
  margin: 1.5em 0 2.5em 0;
  padding: 0 5.5vw;
}

.Services .fp-buttons ul li {
  display: inline-block;
}

.Services .fp-buttons .btn {
  font-family: 'GolanoGrotesque-Bold', Arial, sans-serif;
  border-width: 2px;
  font-size: 2vw;
  text-transform: none;
  letter-spacing: normal;
  padding: .5vw 3.5vw;
  margin-left: 2vw;
  margin-right: 2vw;
}

.Services .fp-buttons .btn:hover {
  color: #fff;
}

.Services .fp-modal {
  margin-top: 10em;
  padding-bottom: 2.5em;
}

.Services .fp-modal p {
  margin: 2em 0 2em 0;
  font-size: .85vw;
}

.Services .fp-modal p.caption {
  text-align: center;
  font-size: 1.15vw;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
}

.Services .fp-modal a {
  display: block;
  font-size: 1.25vw;
  color: #fff;
  text-align: center;
}

.Services .fp-modal a::after {
  content: "\203A";
  color: #00FF99;
  position: relative;
  top: .25vw;
  left: .5vw;
  font-size: 1.75em;
  font-weight: bold;
}

.Services .fp-modal a:hover {
  text-decoration: none;
}

.Services .features {
  margin-top: 2em;
}

.Services .features p {
  font-weight: bold;
}

.Services .featureset {
  margin-top: 5em;
  padding-left: 6%;
  padding-right: 6%;
}

.Services .featureset p {
  font-size: .85vw;
  font-weight: normal;
  padding-left: 2vw;
  padding-right: 2vw;
}

.Services .featureset .col-md-3:last-of-type p {
  padding-left: 3.5vw;
  padding-right: 3.5vw;
}

.Services .featureset h3 {
  font-size: 1.15vw;
  margin-top: 2em;
}

.Services .featureset .icon img {
  min-height: 9.5vw;
}

.Services .testimonial {
  margin-top: 8em;
  padding-left: 20%;
  padding-right: 20%;
}

.Services .testimonial p {
  margin-top: 3em;
  font-size: .75vw;
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  line-height: 1.2;
  letter-spacing: .2vw;
  font-weight: bold;
}

.Services .testimonial blockquote {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.25vw;
  margin-left: 5.5vw;
  margin-right: 5.5vw;
}

.Services .testimonial .icon {
  max-height: 3.5vw;
}

.Services .testimonial img:first-of-type {
  position: absolute;
  left: 7vw;
  top: 2.5em;
}

.Services .testimonial img:last-of-type {
  position: absolute;
  right: 8vw;
  bottom: 3em;
}

.Services .testimonial .col-md-12 {
  border: 3px solid #00FF99;
  border-radius: 20rem;
  padding: 4em 7em;
} 

.Services .widget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Services .detect-revenue {
  padding-top: 17em;
}

.Services .detect-revenue .green-bubble {
  position: relative;
  top: 3.75vw;
  left: -6vw;
  padding-right: 11vw;
}

.Services .detect-revenue .purple-bubble {
  position: relative;
  top: 2vw;
  margin-left: 10.75vw;
  margin-right: 1.5vw;
}

.Services .detect-revenue .col-md-4.center h3 {
  font-size: 2.25vw;
  line-height: 1.1;
}

.Services .detect-revenue .col-md-4.center h3.green {
  font-size: 2.5vw;
}

.Services .detect-revenue .col-md-4.center p {
  text-transform: uppercase;
  font-size: .75vw;
  font-weight: bold;
  margin-top: 1em;
  letter-spacing: .2vw;
}

.Services .detect-revenue .col-md-1,
.Services .protect .col-md-1,
.Services .approve-more .col-md-1 {
  border-left: 1px dashed #00FF99;
}

.Services .detect-revenue .col-md-7 {
  margin-top: -10em;
}

.Services .detect-revenue .swarm .col-md-7 {
  margin-top: 2em;
}

.Services .detect-revenue .col-md-7 p {
  margin-top: 2em;
  padding-right: 15vw;
}

.Services .detect-revenue .col-md-7 h4 {
  margin-top: 3.5em;
  margin-bottom: 3em;
}

.Services .detect-revenue .col-md-7 ul li {
  font-size: 1.05vw;
  text-transform: uppercase;
  word-spacing: .2vw;
  letter-spacing: .2em;
  margin-bottom: 2em;
}

.Services .detect-revenue .col-md-7 ul li img {
  padding-right: 1.5vw;
}

.Services .detect-revenue h2.purple {
  margin-right: 18vw;
  line-height: 1.1;
  padding-top: .5em;
}

.Services .detect-revenue h2 {
  font-size: 3.65vw;
}

.Services .detect-revenue ul {
  padding-left: 0;
}

.Services .detect-revenue .see .col-md-11 {
  padding-left: 0;
}

.Services .detect-revenue .see h2 {
  margin-top: .75em;
  font-size: 5vw;
}

.Services .detect-revenue .see p {
  font-size: 1.35vw;
  margin-bottom: 0;
}

.Services .detect-revenue .swarm .col-md-4 {
  margin-top: 14em;
  padding-bottom: 33em;
}

.Services .detect-revenue .swarm .col-md-4 h3 {
  font-size: 3.25vw;
  margin-bottom: 1em;
}

.Services .detect-revenue .swarm .col-md-4 h4 {
  font-size: .85vw;
}

.Services .detect-revenue .swarm .col-md-4 p {
  font-size: 1.35vw;
  text-transform: none;
}

.Services .detect-revenue .swarm .col-md-4 .btn {
  margin-top: 2em;
}

.Services .detect-revenue .swarm ul {
  margin-top: 11.25em;
}

.Services .detect-revenue .swarm ul li {
  position: relative;
  font-size: .9vw;
  letter-spacing: normal;
  font-weight: normal;
}

.Services .detect-revenue .swarm .ai {
  top: 2.65em;
  left: 18vw;
}

.Services .detect-revenue .swarm .hc {
  top: 1em;
}

.Services .detect-revenue .swarm .int {
  top: 4.65em;
  left: 29vw;
}

.Services .detect-revenue .swarm .oth {
  top: 2.8em;
  left: -4vw;
  font-size: 2vw;
  text-transform: none;
}

.Services .detect-revenue .swarm .us {
  left: 20.5vw;
  font-size: 4vw;
  text-transform: none;
  top: 1em;
}

.Services .detect-revenue .swarm .rev {
  top: 6.75em;
  left: 18.5vw;
}

.Services .detect-revenue .swarm .data {
  top: 15.25em;
  left: 8vw;
}

.Services .detect-revenue .swarm .orders {
  left: 18vw;
}

.Services .protect {
  padding-right: 0;
}

.Services .protect .col-md-6 {
  margin-top: -6.5em;
}

.Services .protect .col-md-5 {
  margin-top: -7em;
}

.Services .protect .col-md-6 h3 {
  font-size: 4vw;
  line-height: 1.2;
}

.Services .protect .col-md-6 h3 span {
  display: block;
}

.Services .protect .col-md-6 p {
  margin-top: 2em;
  margin-right: 6.5vw;
  line-height: 2;
}

.Services .protect .col-md-5 .row {
  padding-right: 7.5%;
}

.Services .protect .col-md-6 .btn {
  margin-top: 2em;
}

.Services .protect .col-md-10 {
  margin-top: 12em;
}

.Services .protect .col-md-10 h3 {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  text-align: center;
  margin-right: 10vw;
}

.Services .protect .col-md-10 h2 {
  font-size: 3.5vw;
}

.Services .protect .dog-ski {
  margin-bottom: 7em;
}

.Services .protect .dog-ski p {
  padding-top: 6em;
  font-size: 1.45vw;
}

.Services .protect .dog-ski .col-md-5 ul {
  padding-left: 0;
  padding-bottom: 10.5em;
}

.Services .protect .dog-ski .col-md-5 ul li {
  font-size: 1.45vw;
}

.Services .protect .dog-ski .col-md-5 ul li img {
  transform: rotate(90deg);
  max-height: 1.25vw;
  margin-right: 2vw;
}

.Services .protect .col-md-5 {
  overflow: hidden;
}

.Services .protect .bubbled {
  display: block;
  border: 2px solid #00ff99;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  padding: 5em 1vw 5em 2vw;
  margin-right: -1vw;
  margin-top: 1em;
  text-align: center;
}

.Services .protect .bubbled img {
  max-height: 3.5vw;
}

.Services .protect .bubbled h3 {
  display: inline-block;
  position: relative;
  top: .25em;
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: 2.25vw;
}

.Services .protect .bubbled h4 {
  font-size: 1vw;
  letter-spacing: normal;
  margin-bottom: 0;
  margin-top: .5em;
  margin-left: 3vw;
}

.Services .protect .col-md-5 h5 {
  margin-top: 3em;
  font-size: 1.1vw;
  text-align: center;
}

.Services .protect .col-md-5 ul {
  margin-top: 1em;
}

.Services .protect .col-md-5 ul li {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: .85vw;
  margin-top: 1.25em;
  margin-bottom: 1em;
}

.Services .protect .col-md-5 ul li:first-of-type {
  margin-top: 2.25em;
}

.Services .approve-more {
  margin-top: 7em;
}

.Services .approve-more .col-md-5 {
  margin-top: -10em;
}
.Services .approve-more .col-md-6 {
  margin-top: -9em;
}

.Services .approve-more h2  {
  font-size: 4vw;
}

.Services .approve-more h4 {
  padding-top: 3em;
}

.Services .approve-more .col-md-5 h3 {
  border-top: 2px solid #00FF99;
  margin-top: 1em;
  margin-right: 5vw;
  padding-top: 1.5em;
}

.Services .approve-more .col-md-6 p {
  margin-top: 6em;
  margin-bottom: 2.25em;
  font-size: 1.35vw;
}

.Services .approve-more .numbers .col-md-11 {
  padding-top: 4em;
  padding-bottom: 4em;
}

.Services .approve-more .numbers .col-md-3 .tile {
  border: 2px solid #00FF99;
  border-radius: 3em;
  padding-top: 1em;
  padding-bottom: 3em;
  min-height: 25em;
}

.Services .approve-more .numbers .col-md-3 .tile p {
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  font-size: 1.1vw;
}

.Services .approve-more .numbers .col-md-3 .tile h5 {
  padding-top: 0.5em;
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.4vw;
}

.Services .approve-more .numbers .col-md-3 .tile .above{
  padding-top: 2em;
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.4vw;
}

.Services .approve-more .numbers .col-md-3 .tile h2 {
  margin-top: .35em;
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 5.5vw;
}

.Services .approve-more .numbers .col-md-3 .tile h2 strong {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
}

.Services .approve-more .numbers {
  margin-bottom: 8em;
}
 
.Services .fp-modal .widget blockquote {
  display: inline-block;
  width: 50%;
  padding-top: 4em;
  padding-bottom: 4em;
  font-weight: bold;
  font-size: .9vw;
  padding-left: 6.75vw;
  padding-right: 6.75vw;
  text-align: center;
}

.Services .fp-modal .widget span {
  display: inline-block;
  border-radius: 1.5rem;
  padding: 4.75em 3vw;
  margin: 4.5vw .5vw;
}

.Services .fp-modal .widget span.diamond {
  margin-left: 0;
}

.Services .fp-modal .widget span.circle {
  margin-right: 0;
}

.Services .col-md-1 h3 {
  transform: rotate(90deg);
  transform-origin: left top 0;
  position: relative;
  left: 1.25vw;
  font-size: 2.25vw;
  top: -4.5em;
}

.Services .fp-modal .widget span.declines:hover::after {
  content: "<blockquote>Approve more orders and eliminate false declines</blockquote";
}

.Services .fp-modal .widget span.declines:hover::after {
  content: "<blockquote>Let us review all orders - Absolute protection and guaranteed chargeback coverage for your business. Eliminate customer insult and never pay for a chargeback again</blockquote";
}

.Services #apruvd-assurance {
  position: relative;
  top: 19em;
}

@media (max-width: 767.98px) {
  
  .Services .welcome {
    background-position: top right;
  }

  .Services .welcome h1 {
    margin-left: 4.5vw;
    margin-top: .2em;
  }

  .Services .welcome .tagline p {
    font-size: 5vw;
  }

  .Services .welcome .cta {
    margin-top: 2em;
    margin-left: 7em;
    margin-bottom: 2em;
  }

  .Services .welcome h2 {
    font-size: 8vw;
  }

  .Services .welcome .lm .more {
    padding-bottom: 3em;
  }

  .Services .fp-guarantee p {
    font-size: 3.5vw;
  }

  .Services .fp-buttons {
    padding-left: 0;
    padding-right: 0;
  }

  .Services .fp-buttons ul {
    padding-left: 0;
  }

  .Services .fp-buttons .btn {
    font-size: 4vw;
    padding: .85vw 5.5vw;
  }

  .Services .fp-buttons ul li:first-of-type .btn {
    margin-left: 0;
  }

  .Services .fp-buttons ul li:last-of-type .btn {
    margin-right: 0;
  }

  .Services #guarantee {
    margin-bottom: .5em;
  }

  .Services .widget img {
    max-width: 100%;
  }

  .Services .fp-modal {
    background-color: #27078a;
    background-image: linear-gradient(#13143e, #14123d);
    margin-left: -30px;
    margin-right: -30px;
    padding-top: 1em;
    padding-bottom: 12em;
  }

  .Services .fp-modal p {
    font-size: 3vw;
  }

  .Services .fp-modal p.caption {
    font-size: 3.5vw;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }

  .Services .fp-modal a {
    font-size: 4.25vw;
  }

  .Services .fp-modal a::after {
    top: .75vw;
    left: 1.5vw;
  }

  .Services .features {
    margin-top: -12em;
  }

  .Services .featureset .icon img {
    max-height: 20vw;
  }

  .Services .featureset p {
    font-size: 2.75vw;
  }

  .Services .featureset h3 {
    font-size: 3.5vw;
    min-height: 2.25em;
  }

  .Services .testimonial blockquote {
    font-size: 2.85vw;
  }

  .Services .testimonial p {
    font-size: 2.5vw;
    margin-bottom: .5em;
  }

  .Services .testimonial img:first-of-type {
    left: 8vw;
    top: 3.5em;
  }

  .Services .testimonial img:last-of-type {
    right: 10vw;
  }

  .Services .testimonial {
    margin-top: 12em;
    padding-left: 10%;
    padding-right: 10%;
  }

  .Services .testimonial .col-md-12 {
    border: 2px solid #00FF99;
    border-radius: 7rem;
    padding: 3em 7em;
  }

  .Services .green-bubble,
  .Services .purple-bubble {
    display: none;
  }

  .Services .detect-revenue {
    margin-top: 15em;
  }

  .Services .detect-revenue h2 {
    font-size: 6vw;
  }

  .Services .detect-revenue .col-md-7 ul {
    padding-left: .5vw;
    margin-top: 0em;
    margin-bottom: 10em;
  }

  .Services .detect-revenue .col-md-7 ul li {
    font-size: 3vw;
  }

  .Services .detect-revenue .col-md-7 ul li img {
    margin-right: 2.5vw;
    max-height: 9vw;
  };

  .Services .detect-revenue .col-md-7 p,
  .Services .protect .col-md-7 p, 
  .Services .approve-more .col-md-7 p {
    font-size: 3vw;
  }

  .Services .col-md-1 h3 {
    left: -1vw;
  }

  .Services .detect-revenue .col-md-1, 
  .Services .protect .col-md-1, 
  .Services .approve-more .col-md-1 {
    min-height: 8em;
  }

  .Services .detect-revenue .see {
    margin-top: 5em;
    padding-top: 3em;
  }

  .Services .detect-revenue .see .col-md-1 {
    display: none;
  }

  .Services .detect-revenue .see h2 {
    margin-top: 0;
    font-size: 7vw;
  }

  .Services .detect-revenue {
    background-position: top;
    background-size: cover;
  }

  .Services .detect-revenue .swarm .col-md-4 {
    display: flex;
    flex-direction: column;
    margin-top: 50em;
    padding-bottom: 0em;
  }

  .Services .detect-revenue .swarm .col-md-4 h3 {
    font-size: 5vw;
  }

  .Services .detect-revenue .swarm .col-md-4 p {
    font-size: 2.75vw;
  }

  .Services .detect-revenue .see p {
    font-size: 3.85vw;
  }

  .Services .detect-revenue .swarm .ai {
    top: .1em;
    left: 7vw;
  }

  .Services .detect-revenue .swarm .hc {
    top: 1.3em;
    left: 40vw;
  }

  .Services .detect-revenue .swarm .orders {
    top: 7vw;
    left: 9.25vw;
  }

  .Services .detect-revenue .swarm .int {
    top: 9.25em;
    left: 52vw;
  }

  .Services .detect-revenue .swarm .rev {
    top: 16.1em;
    left: 7vw;
  }

  .Services .detect-revenue .swarm .data {
    top: 19.15em;
    left: 18vw;
  }

  .Services .detect-revenue .swarm .col-md-4 h4 {
    font-size: 3vw;
    margin-top: 3em;
  }

  .Services .detect-revenue .oth,
  .Services .detect-revenue .us {
    display: none;
  }

  .Services .detect-revenue .swarm .col-md-4 .btn {
    align-self: center;
    margin-bottom: 3em;
  }

  .Services .protect {
    margin-top: 10em;
  }

  .Services .protect .col-md-10 h3 {
    font-size: 3vw;
    text-align: left;
  }

  .Services .protect .col-md-6 h3 {
    font-size: 6vw;
  }

  .Services .protect .bubbled h3 {
    font-size: 6vw;
  }

  .Services .protect .bubbled {
    margin-top: 15em;
  }

  .Services .protect .bubbled h4 {
    font-size: 2.8vw;
    text-align: right;
    margin-right: 16%;
  }

  .Services .protect .col-md-5 h5 {
    font-size: 3.25vw;
    margin-top: 1.75em;
  }

  .Services .protect .col-md-5 ul li {
    font-size: 2.85vw;
    margin-top: 1.5em;
  }

  .Services .protect .col-md-1,
  .Services .approve-more .col-md-1 {
    display: none;
  }

  .Services .protect .row:first-of-type .col-md-1,
  .Services .approve-more .row:first-of-type .col-md-1 {
    display: block;
  }

  .Services .protect .col-md-5 ul {
    padding-left: 0;
  }

  .Services .protect .dog-ski p {
    margin-right: 50%;
    font-size: 3vw;
  }

  .Services .protect .dog-ski .col-md-5 ul li img {
    max-height: 1.85vw;
  }

  .Services .protect .dog-ski .col-md-5 ul li {
    font-size: 2.35vw;
  }

  .Services .approve-more .numbers .col-md-3 .tile h2 {
    font-size: 8vw;
  }

  .Services .approve-more .col-md-5 h3 {
    font-size: 7vw;
    margin-right: 0;
    padding-top: .5em;
  }

  .Services .approve-more .col-md-6 p {
    margin-top: 3em;
    font-size: 3.25vw;
  }

  .Services .approve-more .numbers .col-md-3 .tile .above,
  .Services .approve-more .numbers .col-md-3 .tile h5 {
    font-size: 3vw;
  }

  .Services .approve-more .numbers .col-md-3 .tile h5 {
    padding-top: 0;
  }

  .Services .approve-more .numbers .col-md-3 .tile p {
    font-size: 2.75vw;
    padding: 0;
  }

  .Services .approve-more .numbers .col-md-3 .tile p.mini {
    font-size: 2vw;
  }

  .Services .approve-more .numbers .col-md-3 .tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4em 2vw;
    min-height: 48em;
    border-width: 1px;
    padding: 0 1.75vw;
  }

  .Services .approve-more .numbers .col-md-3:last-of-type .tile p {
    font-size: 2.5vw;
  }

  .Services .detect-revenue .col-md-7 {
    padding-top: 4.5em;
  }

  .Services #apruvd-assurance {
    top: 115em;
  }

}

@media (min-width: 1550px) {

  .Services .approve-more .numbers .col-md-3 .tile p {
    font-size: 1.05vw;
  }

}

@media (max-width: 576px) {

  .Services .testimonial p {
    font-size: 2.25vw !important;
  }

}
