.Pricing .service-intro {
  margin-top: 4em;
}

.Pricing .service-intro h2 {
  font-size: 4vw;
  margin-bottom: .35em;
  padding-left: 20%;
  padding-right: 20%;
}

.Pricing .service-intro h4 {
  margin-bottom: .25em;
}

.Pricing .service-intro h5 {
  font-family: 'GolanoGrotesque';
  font-size: 1.3vw;
  padding-left: 11%;
  padding-right: 11%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Pricing .service-intro p {
  font-size: 1.1vw;
  padding: 0 23%;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.Pricing .welcome ul {
  margin-top: 1em;
  padding-right: 6.5em;
}

.Pricing .welcome ul li {
  font-family: 'GolanoGrotesque';
  font-size: .95vw;
  margin: 1em 0;
}

.Pricing .welcome ul li img {
  max-height: 1.85vw;
  margin-right: 1.25vw;
}

.Pricing .welcome h3 {
  font-family: 'GolanoGrotesque-Medium';
  font-size: 1vw;
}

.Pricing .welcome .asq a.green {
  display: block;
}

.Pricing .welcome .asq .btn {
  margin-top: 2em;
  margin-bottom: 2em;
  color: #401AFF;
  background-color: #00FF99;
}

.Pricing .more {
  padding-top: 3em; 
  padding-bottom: 2.25em;
}

.Pricing .intro h3 {
  font-size: 2.85vw;
}

.Pricing .intro h5 {
  font-size: 1.375vw;
}

.Pricing .intro p {
  padding: .5em 2vw;
}

.Pricing .plans {
  padding-top: 3em;
  padding-bottom: 4em;
}

.Pricing .tiles {
  margin-top: 1.75em;
}

.Pricing .tile {
  border-width: 1px;
  border-radius: 3em;
  padding: 1.25em 2.5vw 2em 2.5vw;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}

.Pricing .tile .box {
  min-height: 14em;
}

.Pricing .tile h3 {
  display: flex;
  color: #fff;
  text-align: left;
  align-items: center;
  margin-top: 0.15em;
  margin-bottom: .75em;
}

.Pricing .tile h3 .num {
  font-size: 2.75vw;
  margin-left: 0;
}

.Pricing .tile h3 span {
  font-size: 1vw;
  text-transform: uppercase;
  line-height: 1.2;
  margin-left: 1.2vw;
}

.Pricing .tile h5 {
  font-family: 'GolanoGrotesque';
  font-size: 1.4vw;
  text-align: right;
  margin-bottom: 1.5em;
}

.Pricing .tile h5 span {
  font-family: 'GolanoGrotesque-Bold';
  font-style: italic;
}

.Pricing .tile h6 {
  text-align: left;
  font-size: 1.05vw;
  font-family: 'GolanoGrotesque-Medium';
  letter-spacing: .1vw;
}

.Pricing .tile ul {
  text-align: left;
  padding-left: 0;
}

.Pricing .tile ul li {
  font-family: 'GolanoGrotesque-Medium';
  font-size: .85vw;
  margin-bottom: 1em;
}

.Pricing .tile ul li img {
  max-height: 1.5vw;
  margin-right: .5vw;
}

.Pricing .tile p {
  font-family: 'GolanoGrotesque-Medium';
  font-size: .925vw;
  text-align: left;
  padding-right: 2.5vw;
}

.Pricing .tile .btn {
  margin-top: .5em;
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: 1rem;
  letter-spacing: normal;
  text-transform: none;
  background: #241498;
  color: #fff;
  border-width: 0;
  max-width: 25.5vw;
  border-radius: 5rem;
  padding: 0.85vw 2.65vw;
}

.Pricing .tile p .small {
  font-size: .8vw;
}

.Pricing .tile.complete {
  color: #401AFF;
  background: linear-gradient(to left top, #89fb96, #17e690);
  border-color: #00FF99;
}

.Pricing .tile.revive {
  color: #fff;
  background: #4621ff;
  border-color: #2F12CC;
}

.Pricing .tile.custom {
  color: #241498;
  background: #c2c2c2;
  border-color: #C7C7C7;
}

.Pricing .discl {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .85vw;
}

.Pricing .fp p {
  font-size: 1vw;
}

.Pricing .start {
  text-align: right;
  padding-right: 3vw;
}

.Pricing .start h4 {
  text-transform: none;
  font-size: 1.1vw;
  letter-spacing: normal;
  margin-bottom: 1em;
}

.Pricing .start .btn {
  padding: .5vw 5vw;
  font-size: 1vw;
  margin-right: 1vw;
}


@media (max-width: 767.98px) {

  .Pricing .welcome h1 {
    padding-left: 2%;
  }

  .Pricing .service-intro {
    margin-top: 4em;
  }

  .Pricing .service-intro .center {
    text-align: left;
  }

  .Pricing .service-intro h2 {
    font-size: 10vw;
    margin-bottom: 0.35em;
    padding-left: 0;
    padding-right: 0;
  }

  .Pricing .service-intro h5 {
    font-family: 'GolanoGrotesque';
    font-size: 4.3vw;
    padding-left: 0;
    padding-right: 11%;
  }

  .Pricing .welcome ul {
    margin-top: 0.25em;
    padding: 0;
  }

  .Pricing .welcome ul li {
    font-size: 3.65vw;
    margin: 1em 0;
  }

  .Pricing .welcome-list {
    padding: 0;
  }

  .Pricing .welcome ul li img {
    max-height: 5.75vw;
    margin-right: 2.5vw;
  }

  .Pricing .welcome h3 {
    font-size: 4.5vw;
  }

  .Pricing .welcome .asq a.green {
    font-weight: bold;
    font-size: 3.4vw;
  }

  .Pricing .welcome .asq .btn {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    text-transform: uppercase;
    font-size: 3.85vw;
    font-weight: bold;
    letter-spacing: .45vw;
  }

  .Pricing .more {
    padding-top: 1.25em;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .Pricing .more a {
    font-family: 'GolanoGrotesqueExtraBold', Arial, sans-serif;
    font-size: 4.75vw;
    font-weight: bold;
  }

  .Pricing .intro h2 {
    display: block;
    opacity: 7%;
    font-family: 'GolanoGrotesque', Arial, sans-serif;
    font-size: 25vw;
    margin-bottom: 0;
  }

  .Pricing .intro h3 {
    font-size: 8.5vw;
    margin-top: -0.85em;
  }

  .Pricing .intro h5 {
    font-size: 4.75vw;
    margin-top: 1.5em;
  }
  
  .Pricing .intro p:first-of-type {
    display: none;
  }

  .Pricing .intro p {
    padding: 0;
    margin-top: 1em;
    margin-bottom: 1.5em;
    font-size: 3.85vw;
    line-height: 1.2;
  }

  .Pricing .intro p strong {
    font-family: 'GolanoGrotesqueLight', Arial, sans-serif;
    font-weight: normal;
  }

  .Pricing .discl {
    line-height: 1.3;
    font-size: 3.1vw;
  }

  .Pricing .fp p {
    font-size: 2.8vw;
    line-height: 1.3;
    text-align: left;
  }

  .Pricing h4.mob-only {
    font-size: 4.85vw;
    text-transform: none;
    margin-top: 1em;
    letter-spacing: normal;
  }

  .Pricing .start {
    text-align: center;
    padding-right: 0;
  }

  .Pricing .start h4 {
    font-size: 7vw;
    font-weight: bold;
    font-family: 'GolanoGrotesqueBold', Arial, sans-serif;
  }

  .Pricing .start h4 em {
    font-family: 'GolanoGrotesqueSemiBold', Arial, sans-serif;
    display: block;
    font-size: 6vw;
    font-weight: normal;
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .Pricing .start .btn {
    padding: 1.25vw 8.5vw;
    font-size: 5.25vw;
    text-transform: none;
    margin-right: 0;
  }

  .Pricing .tile {
    padding: 1.25em 5vw 2em 5vw;
    max-width: 97.5%;
    border-radius: 7.5em;
  }

  .Pricing .tile h3 {
    margin-top: .5em;
    margin-bottom: .5em;
  }

  .Pricing .tile h3 .num {
    font-size: 10vw;
  }

  .Pricing .tile h3 span {
    font-weight: normal;
    font-size: 4.5vw;
    line-height: 1;
  }

  .Pricing .tile h6 {
    font-size: 3.75vw;
  }

  .Pricing .tile .title {
    text-align: center;
    font-size: 4.5vw;
    padding-top: .25em;
  }

  .Pricing .tile p, 
  .Pricing .tile p.desc {
    font-size: 3.2vw;
  }

  .Pricing .tile p .small {
    font-size: 2.5vw;
  }

  .Pricing .tile ul li {
    font-size: 3vw;
  }

  .Pricing .tile ul li img {
    max-height: 5vw;
    margin-right: 3vw;
  }

  .Pricing .tile .btn {
    font-size: 1rem;
    padding: 2.85vw 7.65vw;
    max-width: 50vw;
  }

  .Pricing .discl {
    margin-top: 4em;
  }
  
  .carousel-indicators {
    bottom: -12em;
  }

}

@media (min-width: 1842.98px) {

}
