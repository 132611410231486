.Home .welcome h1 {
  padding-left: 7vw;
}

.Home .slogan p {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.575vw;
  padding: 0 6% 0 5%;
  margin-top: 11em;
}

.Home .welcome .cta {
  margin-top: 3em;
}

.Home .cta::after {
  left: 21.35vw;
  top: 30.75vw;
}

.Home .cta:hover::after {
  top: 36.25em;
  left: 9.25vw;
}

.Home .menu .nav-item:first-of-type a {
  color: #00FF99;
  border-bottom: 1px solid #00FF99;
  border-bottom-width: 80%;

  /* pill form of "active" menu item
  border: 1px solid #00FF99;
  border-radius: 5rem;
  padding: .5vw 1vw;
  margin: 0 .5vw;
  */
}

.Home .guarantee {
  margin-top: 11em; 
}

.Home .guarantee h4 {
  margin-bottom: 2.5em;
}

.Home .guarantee p {
  font-size: 1.8vw;
  padding: 1.15em 22vw;
}

.Home .we-are-apruvd {
  padding-top: 9.5em;
  padding-bottom: 17.5em;
}

.Home .we-are-apruvd h5 {
  margin-bottom: 2em;
}

.Home .we-are-apruvd p {
  font-size: 1.15vw;
  margin-bottom: 2em;
  margin-left: 44vw;
}

.Home .we-are-apruvd a {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.2vw;
  text-decoration: none;
  color: #fff;
}

.Home .we-are-apruvd a:hover {
  color: #00FF99;
}

.Home .happy-customers {
  padding-bottom: 13.425em;
}

.Home .happy-customers .col-md-6 {
  margin-top: 11em;
}

.Home .happy-customers h4 {
  margin-bottom: 2em;
}

.Home .happy-customers h3 {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  font-size: 3vw;
  line-height: 1.2;
}

.Home .happy-customers h3 span:first-of-type {
  display: block;
}

.Home .happy-customers p {
  margin-right: 8vw;
  margin-top: 2em;
}

.Home .happy-customers .customer-icons {
  margin-top: 3em;
  position: relative;
  left: -2vw;
}

.Home .happy-customers .customer-icons .col-md-4 {
  padding: 0;
}

.Home .happy-customers .customer-icons img {
  max-height: 38px;
}

.Home .happy-customers .customer-icons span {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.15vw;
  font-weight: bold;
  display: block;
  padding-top: 1.5em;
}

.Home .happy-customers .btn {
  margin-top: 3em;
}

.Home .all-about-customers {
  padding-top: 8em;
  padding-bottom: 2.85em;
}

.Home .all-about-customers hr {
  color: #fff;
  border-bottom: 1px dotted #00FF99;
}

.Home .all-about-customers h2 {
  font-size: 3.325vw;
}

.Home .all-about-customers h3 {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.9vw;
  line-height: 2.2vw;
}

.Home .all-about-customers h4 {
  margin-top: 3.25em;
}

.Home .all-about-customers a:hover h4 {
  color: #00FF99;
}

.Home .all-about-customers a h4 {
  color: #fff;
  display: inline-block;
}

.Home .all-about-customers a:hover img {
  transition: all 0 ease-in-out;
  box-shadow: 0 0 20px 0.1rem rgb(0 255 150 / 50%) inset, 0 0 10px 0.35rem rgb(0 255 150 / 50%);
}

.Home .all-about-customers a img {
  display: inline-block;
  margin-top: 0;
  margin-left: .5vw;
  transform: rotate(270deg);
}

.Home .all-about-customers h5 {
  font-family: 'GolanoGrotesque', Arial, sans-serif;
  font-size: 1.7vw;
  line-height: 2.5vw;
  padding: 0 15%;
  margin-top: 4em;
}

.Home .all-about-customers p {
  font-size: 1.09vw;
  line-height: 1.75;
  margin-top: 1.5em;
}

.Home .all-about-customers .btn {
  margin-top: 1.5em;
}

.Home .what-we-do {
  margin-top: 6em;
  padding-bottom: 30em;
  padding-left: 3%;
}

.Home .what-we-do h2 {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  font-size: 4.45vw;
  line-height: 1.1;
}

/* TO DO */
.Home .what-we-do h3 {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: 32px;
  padding: 65px 200px 0 200px;
}

.Home .what-we-do h4 {
  margin-top: 14em;
}

.Home .what-we-do p {
  margin-top: 2em;
}

.Home .what-we-do .btn {
  margin-top: 3em;
}

.Home .what-we-do .abt-svg {
  display: inline-flex;
  cursor: pointer;
}

.Home .what-we-do .abt-svg:hover svg.abt-shadow {
  opacity: 1.0 !important;
}

.Home .what-we-do ul li svg.abt-shadow {
  position: absolute;
  left: 0;
  top: .2em;
  max-height: 1.2vw;
  border-radius: 5rem;
  box-shadow: 0 0 25px 1.1rem rgb(64, 0, 255, 0.9)
}

.Home .what-we-do ul li.abt-one svg.abt-shadow,
.Home .what-we-do ul li.abt-two svg.abt-shadow {
  top: .9em;
}

.Home .what-we-do ul li span {
  margin-left: 3.5vw;
  font-size: 1.3vw;
  display: inline-block;
  width: 50%;
  opacity: 0;
}

.Home .what-we-do .abt-one {
  position: relative;
  top: 8.25em;
  left: 36%;
}

.Home .what-we-do ul li.abt-one span {
  vertical-align: middle;
  width: 30%;
}

.Home .what-we-do .abt-two {
  position: relative;
  top: 12em;
  left: 0;
}

.Home .what-we-do ul li.abt-two span {
  vertical-align: middle;
  width: 45%;
}

.Home .what-we-do .abt-three {
  position: relative;
  top: 21em;
  left: 71.25%;
}

.Home .what-we-do ul li.abt-three span {
  margin-left: -14vw;
  width: 30%;
}

.Home .what-we-do .abt-four {
  position: relative;
  top: 30em;
  left: 3.5vw;
}

.Home .what-we-do .abt-five {
  position: relative;
  top: 34em;
  left: 36vw;
}

.Home .what-we-do .abt-six {
  position: relative;
  top: 44em;
  left: 54%;
}

.Home .value blockquote {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  padding-left: 8%;
  padding-right: 8%;
}

.Home .value blockquote span p em {
  color: white;
}

.Home .value .btn {
  margin-top: 2em;
  margin-left: 5vw;
  font-size: .8vw;
  padding: 1.85vw 2.65vw;
}

.Home .value blockquote span {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  font-size: 2.7vw;
  display: block;
  text-align: left;
  padding-bottom: .3em;
  padding-left: 1.5em;
}

.Home .value blockquote img {
  max-height: 2.25vw;
}

.Home .value blockquote img.icon-flipped {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.Home .value blockquote span span {
  display: inline;
  padding-left: 1.25vw;
}

.Home .value blockquote p {
  text-align: center;
  font-size: 1.25vw;
}

.Home .value blockquote p span {
  text-align: center;
  font-size: 1.25vw;
}

.Home .value h3 {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  font-size: 2.5vw;
  margin-top: 1.55em;
  padding-left: 2em;
  line-height: 3.15vw;
}

.Home .value .col-md-7 .row {
  margin-top: 3em;
}

.Home .big-circle {
  padding-bottom: 16.75em;
}

.Home .big-circle .col-md-7 {
  padding: 0 11em;
}

.Home .big-circle .col-md-7 h4 {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: .85vw;
  margin-top: 20.5em;
  margin-bottom: 1.5em;
}

.Home .big-circle .col-md-5 h4 {
  line-height: 2.2vw;
  margin-top: 18em;
  padding-left: 12.5%;
  padding-right: 12.5%;
}

.Home .big-circle h3 {
  font-family: 'GolanoGrotesque-Heavy', Arial, sans-serif;
  font-size: 3vw;
  margin-top: .65em;
  line-height: 3.25vw;
}

.Home .big-circle p {
  margin-top: 1.5em;
  padding: 0 9%;
}

.Home .big-circle img.icon {
  max-height: 50px;
  margin-right: 2vw;
}

.Home .big-circle .btn {
  margin-top: 6.5em;
  font-size: .9vw;
}

.Home .big-circle ul {
  list-style-type: none;
  margin-top: 1%;
  margin-left: 5vw;
}

.Home .big-circle ul li {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  font-size: 2.1vw;
  margin-bottom: 1em;
}

.Home .plans h2 {
  font-family: 'GolanoGrotesque-Heavy', Arial, sans-serif;
  font-size: 4.65vw;
}

.Home .plans .col-md-6 h3 {
  font-family: 'GolanoGrotesque-Heavy', Arial, sans-serif;
  font-size: 5vw;
  line-height: 5.15vw;
  margin-top: 50px;
}

.Home .plans .col-md-4 h3 {
  margin-top: .5em;
}

.Home .plans img {
  max-height: 10vw;
}

.Home .plans {
  margin-top: 1em;
  padding-bottom: 9em;
}

.Home .plans h3 {
  font-family: 'GolanoGrotesque-Light', Arial, sans-serif;
}

.Home .plans h3 strong {
  font-family: 'GolanoGrotesque-Bold', Arial, sans-serif;
}

.Home .plans .col-md-4 p {
  padding-right: 0;
}

.Home .plans .col-md-4 a {
  color: #ddd;
  border-bottom: 1px solid transparent;
}

.Home .plans .col-md-4:first-of-type a {
  margin-right: 2.25vw;
}

.Home .plans .col-md-4:last-of-type a {
  margin-right: 0.75vw;
}

.Home .plans .col-md-4 a {
  margin-right: 1.75vw;
}

.Home .plans .col-md-4 a:hover {
  text-decoration: none;
  border-bottom: 1px solid #ddd;
}

.Home .plans .col-md-4 a img {
  position: relative;
  top: -0.1vw;
  max-height: 1.05vw;
}

.Home .plans h4 {
  margin-top: 7em;
}

.Home .plans p:first-child {
  font-size: 20px;
}

.Home .plans .btn {
  margin-top: 3em;
}

.Home .plans p {
  margin-top: 1em;
  padding-right: 5vw;
}

.Home .plans .small {
  margin-bottom: 2.15em;
  font-size: 100%;
}

.Home .plans .col-md-7 .btn {
  margin-top: 65px;
}

.Home .plans .col-md-5 .btn {
  padding: 22px 60px;
  margin-top: 35px;
  margin-bottom: 200px;
}

.Home .plans .big-quote {
  margin-top: 33em;
}

.Home .get-started {
  padding: 0 25% 8em 25%;
}

.Home .get-started h2 {
  font-family: 'GolanoGrotesque-ExtraBold', Arial, sans-serif;
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-size: 3.8vw;
}

.Home .plans p a {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}

.Home .plans p a:hover {
  border-bottom: 1px solid #fff;
}

.Home .plans p a span::after {
  content: "\203A";
  font-size: 1.25vw;
}

.Home .plans .quote {
  margin-left: 6vw;
}

.Home .plans .quote img:last-child {
  float: right;
  margin-top: 25px;
  margin-right: 25px;
}

.Home .plans .quote p {
  padding: 1vw 7.5vw 1vw 4vw;
}

.Home .plans .quote p.center {
  font-size: 1.5vw;
}

.Home .plans .quote .small {
  font-family: 'GolanoGrotesque-Bold', Arial, sans-serif;
  letter-spacing: .2em;
  padding: 0 7.5vw 0 0;
  margin: 5px 0;
}

.Home .case-studies .center.col {
  border: 3px solid #00FF99;
  border-radius: 20rem;
  padding: 4em 7em;
}

.Home .case-studies span {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  padding: 1vw 1.25vw;
  border: 2px solid #00FF99;
  border-radius: 20rem;
}

.Home .case-studies h4 {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  font-size: .85vw;
  text-transform: none;
  margin-bottom: 1.5em;
}

.Home .case-studies p {
  font-size: 16px;
}

.Home .case-studies h5 {
  font-family: 'GolanoGrotesque-SemiBold', Arial, sans-serif;
  font-size: 24px;
  margin-bottom: 40px;
}

.Home .case-studies .first {
  padding-top: 10em;
}

.Home .case-studies .second {
  padding-top: 60px;
}

.Home .case-studies a {
  font-family: 'GolanoGrotesque-Medium', Arial, sans-serif;
  font-size: .9vw;
  text-transform: uppercase;
  letter-spacing: .15em;
  color: #00FF99;
}

.Home .case-studies a::after {
  content: "\203A";
  font-size: 1.2vw;
}

.Home .case-studies a:hover {
  text-decoration: none;
  border-bottom: 1px solid #00FF99;
}

@media (max-width: 767.98px) {

  .Home .happy-customers .btn,
  .Home .big-circle .btn,
  .Home .value .btn {
    font-size: 2.75vw;
    padding: 4.5vw 9vw;
  }

  .Home .big-circle h3,
  .Home .value h3 {
    font-size: 7.5vw;
    line-height: 1;
  }

  .Home .value blockquote p {
    font-size: 2.25vw;
    padding: 0 12%;
  }

  .Home .value blockquote span {
    font-size: 5vw;
  }

  .Home article:first-of-type {
    padding-top: 6em;
  }

  .Home .row {
    margin-right: 0;
  }

  .Home .slogan p {
    margin-top: 1em;
    padding-left: 7vw;
    font-size: 4vw;
  }

  .Home .welcome .cta {
    margin-top: 2em;
  }

  .Home .plans .col-md-8 .row .col-md-4 {
    margin-bottom: 2em;
  }

  .Home .guarantee {
    margin-top: 2.25em;
  }

  .Home .guarantee h2 {
    padding: 0 12%;
  }

  .Home .guarantee p {
    margin-top: 1.5em;
    margin-left: 2em;
    padding: 0 18%;
    font-size: 3vw;
    font-weight: bold;
  }

  .Home .what-we-do {
    margin-top: 80em;
    padding-left: 0;
    padding-bottom: 2em;
    background-position: center;
  }

  .Home .what-we-do .col-md-6:last-of-type {
    top: -130em;
    padding-right: 7.5%;
  }

  .Home article {
    padding: 0;
  }

  .Home .what-we-do h4 {
    margin-top: 4.5em;
  }

  .Home .what-we-do h2 {
    font-size: 11vw;
  }

  .Home .what-we-do p {
    margin-left: 38vw;
    font-weight: bold;
  }

  .Home .what-we-do .btn {
    margin-top: 2.5em;
  }

  .Home .we-are-apruvd {
    padding: 4em 3em 3em 3em;
  }

  .Home .we-are-apruvd p {
    font-size: 3vw;
    line-height: 1.75;
    margin-left: 5vw;
  }

  .Home .we-are-apruvd a {
    font-size: 3vw;
    font-weight: bold;
  }

  .Home .we-are-apruvd h5 {
    margin-bottom: 1.5em;
  }

  .Home .happy-customers .col-md-6 {
    margin-top: 30em;
  }

  .Home .happy-customers {
    padding: 0 8em;
  }

  .Home .happy-customers h4 {
    margin-bottom: 2em;
  }

  .Home .happy-customers h3 {
    font-size: 7.5vw;
  }

  .Home .happy-customers p {
    font-size: 3vw;
    padding-right: 7em;
  }

  .Home .happy-customers .customer-icons {
    margin-top: 15em;
  }

  .Home .happy-customers .customer-icons img {
    max-height: 22px;
  }

  .Home .happy-customers .customer-icons span {
    padding-top: 1.5em;
    font-size: 2.35vw;
  }

  .Home .big-circle .btn {
    margin-top: 0;
  }

  .Home .big-circle {
    margin-top: 3em;
  }

  .Home .big-circle .col-md-7 h4 {
    font-size: 3vw;
    margin-top: 13em;
  }

  .Home .big-circle .col-md-5 {
    margin-top: 3em;
  }

  .Home .big-circle .col-md-7 {
    padding: 0 7.5em 0 12.5em;
  }

  .Home .big-circle .col-md-5 h4 {
    display: none;
  }

  /* .Home .big-circle .col-md-5 h4 {
    margin-top: 5em;
    font-size: 3vw;
    line-height: 1.5;
    padding-left: 17%;
    padding-right: 17%;
  } */

  .Home .big-circle .col-md-5 ul li img {
    padding-right: 3vw;
  }

  .Home .big-circle h3 {
    margin-top: .75em;
    padding: 0 2%;
  }

  .Home .big-circle p {
    font-size: 3.25vw;
    margin-top: 1.35em;
    padding: 0;
  }

  .Home .big-circle img.icon {
    max-height: 28px;
  }

  .Home .value blockquote span {
    padding-left: 0;
    text-align: center;
  }

  .Home .value blockquote img {
    max-height: 3.25vw;
  }

  img.icon, img.icon-flipped {
    max-height: 20px;
  }

  .Home .big-circle ul li:first-of-type {
    margin-left: 0vw;
  }

  .Home .big-circle ul li:last-of-type {
    margin-left: 20vw;
  }

  .Home .big-circle ul li {
    margin-left: 10vw;
    font-size: 5.5vw;
    margin-bottom: 1.1em;
  }

  .Home .big-circle ul {
    margin-bottom: 10em;
  }

  .Home .all-about-customers h2 {
    font-size: 6.5vw;
  }

  .Home .all-about-customers {
    margin-top: 0;
    padding-top: 0;
    background-position-y: 48em;
    padding-bottom: 42.5em;
  }

  .Home .all-about-customers p {
    font-size: 2.75vw;
    margin-top: 3em;
    padding: 0px 8%;
  }

  .Home .all-about-customers h3 {
    font-family: 'GolanoGrotesque-Bold', Arial, sans-serif;
    font-size: 4.25vw;
  }

  .Home .all-about-customers h5 {
    text-align: right;
    font-size: 3.5vw;
    line-height: 1.5;
    padding: 0px 2em 0 9em;
    margin-top: 10em;
  }

  .Home .all-about-customers h5 em {
    display: block;
  }

  .Home .all-about-customers h4 {
    text-align: right;
    padding-right: .75em;
    margin-top: 3.25em;
  }

  .Home .all-about-customers .col-md-6:last-of-type a:last-of-type {
    display: block;
    text-align: right;
    padding-right: 8em;
  }

  .Home .all-about-customers img.down-arrow {
    max-height: 5vw;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .Home .value h3 {
    padding-left: 1em;
    padding-right: 1em;
  }

  .Home .value .row:first-child {
    flex-direction: column-reverse;
  }

  .Home .case-studies .center.col {
    border: 3px solid transparent;
    border-radius: 20rem;
    padding: 4em 12em;
  }

  .Home .case-studies a {
    margin-bottom: 2.5em;
    display: block;
  }

  .Home .case-studies span {
    margin: 1em 0;
    display: inline-block;
    font-size: 4vw;
    padding: 2vw 3.75vw;
    border: 1px solid #00FF99;
  }

  .Home .case-studies .first span {
    padding: 2vw 4.25vw;
  }

  .Home .case-studies .first,
  .Home .case-studies .second {
    padding-top: 0;
  }

  .Home .case-studies h4 {
    font-size: 3.85vw;
  }

  .Home .case-studies a {
    font-size: 2.9vw;
  }

  .Home .case-studies a::after {    
    font-size: 4vw;
  }

  .Home .get-started {
    padding-top: 0px;
  }

  .Home .plans {
    margin-top: 10em;
    padding: 0 7.5%;
    background-size: contain;
  }

  .Home .plans h4.small {
    display: none;
  }

  .Home .plans h2 {
    margin-top: 3.25em;
    font-size: 8.25vw;
  }

  .Home .plans .col-6 p,
  .Home .plans .col-12 p {
    margin-top: .5em;
    font-size: 3vw;
  }

  .Home .plans p {
    font-size: 3.25vw;
    padding-right: 18vw;
    margin-top: 2em;
  }

  .Home .plans .col-md-4 a img {
    max-height: 1.5vw;
  }

  .Home .plans .small {
    font-size: 3vw;
    margin-bottom: 2em;
  }

  .Home .plans p a span::after {
    font-size: 3.25vw;
  }

  .Home .plans img {
    max-height: 25vw;
  }

  .Home .plans .col-md-6 h3 {
    margin-top: 2em;
  }

  .Home .plans h3 {
    margin: .75em 0 1.5em 0;
  }

  .Home .plans .find-plan {
    justify-content: center;
  }

  .Home .plans .row .col-md-8 .row {
    padding-top: 4em;
  }

  .Home .plans .col-md-4:first-of-type a {
    margin-right: 3.5vw;
  }

  .Home .plans .col-md-4 a {
    margin-right: 2.25vw;
  }

  .Home .plans .col-md-4:last-of-type a {
    margin-right: 22.5vw;
  }

  .Home .plans .col-6 h3,
  .Home .plans .col-12 h3 {
    margin-top: .5em;
    margin-bottom: .5em;
    font-size: 4.25vw;
  }

  .Home .plans .btn {
    margin-top: 0;
  }

  .Home .plans .big-quote {
    margin-top: 14em;
  }

  .Home .plans .big-quote .btn {
    margin-top: 5em;
  }

  .Home .plans .quote {
    margin-top: 30em;
    margin-left: 3em;
  }

  .Home .plans .quote img {
    max-height: 6vw;
  }

  .Home .plans .big-quote h3 {
    font-size: 10vw;
    line-height: 1;
    margin-bottom: 0;
  }

  .Home .plans .quote p.center {
    font-size: 4vw;
    line-height: 2;
  }

  .Home .plans .quote p {
    padding: 1vw 15vw 1vw 10vw;
  }

  .Home .plans .quote .small {
    padding-right: 10vw;
  }

  .Home .case-studies {
    border-top: 2px solid #00FF99;
    border-bottom: 2px solid #00FF99;
    margin-top: 15em;
  }

  .Home .case-studies h5 {
    text-align: left;
    margin-top: 1.5em;
  }

  .Home .case-studies .col {
    padding-left: 8vw
  }

  .Home .get-started h2 {
    font-size: 7vw;
  }

  .Home .get-started {
    padding: 0 10% 30em 10%;
  }

  .Home .get-started .btn {
    margin-top: .5em;
  }

  .Home .what-we-do ul {
    padding-left: 7%;
  }

  .Home .what-we-do ul li svg {
    height: 2vw;
    width: 2vw;
  }

  .Home .what-we-do ul li svg.abt-shadow {
    top: 1.1em;
    max-height: 2vw;
    opacity: 0.6 !important;
  }
  
  .Home .what-we-do ul li.abt-one svg.abt-shadow,
  .Home .what-we-do ul li.abt-four svg.abt-shadow,
  .Home .what-we-do ul li.abt-five svg.abt-shadow {
    top: 2.5em;
  }

  .Home .what-we-do ul li.abt-two span {
    width: 35%;
  }

  .Home .what-we-do ul li.abt-two svg.abt-shadow {
    top: 4.5em;
  }
  
  .Home .what-we-do ul li span {
    margin-left: 3.5vw;
    font-size: 3vw;
    width: 50%;
    opacity: 1 !important;
  }
  
  .Home .what-we-do .abt-one {
    top: 107em;
    left: 36.5%;
  }
  
  .Home .what-we-do .abt-two {
    top: 30em;
    left: 0;
  }

  .Home .what-we-do ul li.abt-four span,
  .Home .what-we-do ul li.abt-five span {
    width: 35%;
    vertical-align: middle;
  }

  .Home .what-we-do .abt-three {
    top: 38em;
    left: 52%;
  }
  
  .Home .what-we-do ul li.abt-three span {
    margin-left: -19vw;
  }
  
  .Home .what-we-do .abt-four {
    top: 53em;
    left: 6vw;
  }
  
  .Home .what-we-do .abt-five {
    top: 1em;
    left: 53vw;
  }
  
  .Home .what-we-do .abt-six {
    top: 44em;
    left: 51%;
  }

}

@media (min-width: 1024px) {
  .Home .what-we-do .abt-one {
    left: 36.5%;
  }
  .Home .what-we-do .abt-three {
    left: 71.5%;
  }
  .Home .what-we-do .abt-six {
    left: 54%;
  }
}

@media (min-width: 1280px) {
  .Home .what-we-do .abt-one {
    left: 37%;
  }

  .Home .what-we-do .abt-six {
    left: 55%;
  }
}

@media (min-width: 1550px) {
  .Home .what-we-do .abt-one {
    left: 38%;
  }
  .Home .what-we-do .abt-six {
    left: 54.75%;
  }
}

@media (min-width: 1856px) {
  .Home .what-we-do .abt-one {
    left: 38.5%;
  }
  .Home .what-we-do .abt-six {
    left: 55%;
  }
}
