.Contact .lets-connect {
  padding-top: 8em;
}

.Contact .connect {
  margin-bottom: 45px;
}

.Contact .more {
  padding-top: 6vw;
  padding-bottom: 4.5vw;
}

.Contact .connect p {
  margin-top: 1.5em;
  width: 80%;    
  font-size: 1.15vw;
}

.Contact .connect h3 {
  padding-right: 8.5vw;
}

.Contact .connect a {
  color: #00FF99;
}

.Contact .connect a img {
  position: relative;
  top: -1px;
  left: -2px;
  width: 1.67vw;
  height: 1.67vw;
}

.Contact .lets-connect h1 {
  margin-top: 25px;
}

.Contact .contact-info {
  padding-top: 100px;
  padding-bottom: 115px;
}

.Contact .contact-info h4 {
  padding-left: 6%;
  margin-bottom: 45px;
}

.Contact .contact-info p {
  padding-left: 17%;
  padding-right: 17%;
  font-size: 1.6vw;
}

.Contact .FormFeedback {
  margin-bottom: 0;
}

.Contact .FormFeedback p {
  font-size: .75vw;
  margin: 0;
}

.Contact form .row  {
  margin-bottom: 30px;
}

.Contact .contact-info .right {
  padding-right: 11%;
}

.Contact .contact-info h3 {
  font-size: 2.75vw;
}

.Contact .contact-info hr {
  margin-left: 27%;
  margin-top: .75em;
  margin-bottom: 2em;
  border-top: 1px solid #00FF99;
}

.Contact .contact-info h6 {
  font-size: 1.05vw;
  font-weight: bold;
  letter-spacing: .35em;
}

.Contact .contact-form h3 {
  margin-bottom: 0;
}

.Contact .contact-form .btn {
  font-size: .85vw;
  padding: 1.25vw 1.75vw;
  border-width: 2px;
}

.Contact .contact-form input {
  background-color: #1A0E85;
  border-width: 1px;
  border-radius: 5rem;
  border-style: hidden;
  margin: 1.25em 0;
  color: #fff;
  padding: 20px 34px;
  display: block;
  width: 100%;
  outline: none;
  box-shadow: none;
}

.Contact .contact-form input:focus {
  background-color: #4025FF;
}

.Contact .contact-form input:focus::placeholder {
  color: #fff;
}

.Contact .contact-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4000FF;
  opacity: 1; /* Firefox */
}

.Contact .ContactForm .col-md-6 {
  padding: 0 5px;
}

.Contact .ContactSuccessMessage {
  padding: 5em 0 0 0;
}

.Contact .ContactSuccessMessage p {
  font-size: 2vw;
}

@media (max-width: 767.98px) {

  .Contact {
    background-size: auto;
  }

  .Contact .lets-connect .row {
    flex-direction: column-reverse;
  }

  .Contact .connect h3 {
    margin-top: 1em;
  }

  .Contact .ContactSuccessMessage p {
    font-size: 4.5vw;
  }

  .Contact .ContactSuccessMessage {
    padding-top: 2em;
  }

  .Contact .connect p {
    font-size: 3.5vw;
    width: 95%;
  }

  .Contact .contact-form h3 {
    font-size: 7vw;
    margin-bottom: 1.5em;
  }

  .Contact .contact-info p {
    font-size: 3vw;
    margin-bottom: 2.5em;
  }

  .Contact .contact-info h3 {
    font-size: 6vw;
  }

  .Contact .contact-info h6 {
    font-size: 2vw;
  }

  .Contact .contact-info .right {
    margin-top: 6em;
  }

  .Contact .contact-info {
    padding-top: 18em;
    padding-bottom: 13em;
  }

  .Contact .contact-form .btn {
    font-size: 1.75vw;
    padding: 4.5vw 9vw;
  }

}
